<script setup lang="ts">
import RatingType from '@/Enums/RatingType'
import InputStars from '@/Components/Input/InputStars.vue'
import BaseRatingForm from '@/Components/Ratings/BaseRatingForm.vue'
import type RatingFormComponent from '@/Types/Ratings/RatingFormComponent'
import { useForm } from '@inertiajs/vue3'
import type { RatingForm } from '@/Types/Ratings/RatingForm'

const props = defineProps<RatingFormComponent>()

interface StarValue {
    id: number | string;
    value: number;
    title: string;
}

const form = useForm<RatingForm<StarValue>>({
    rateable_type: props.rateableType,
    rateable_id: props.rateable.id,
    type: RatingType.STAR as RatingType,
    title: '',
    description: '',
    meta: {
        values: [
            { id: 0, value: 0, title: '' }
        ]
    }
})
</script>

<template>
    <BaseRatingForm
        v-model:form="form"

        :default-value="{title: '', value: 0}"
    >
        <template #element="{ element }">
            <div class="pt-4 md:pt-2">
                <InputStars
                    v-model="element.value"
                    class="-mb-2" />
            </div>
        </template>
        <template #actions>
            <slot name="actions" />
        </template>
    </BaseRatingForm>
</template>
