<script setup>
import flatPickr from 'vue-flatpickr-component'
import InputLabel from '@/Components/Input/InputLabel.vue'
import { onMounted, ref } from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    modelValue: [String, Date, Array],
    minDate: [String, Date, null],
    maxDate: [String, Date, null],
    label: String,
    placeholder: String,
    range: {
        type: Boolean,
        default: false
    },
    time: {
        type: Boolean,
        default: true
    },
    futureOnly: {
        type: Boolean,
        default: true
    },
    error: {
        type: [String, null],
        default: null
    }
})

const shortAltFormat = 'M j'
const fullAltFormat = 'F J Y'

const options = ref({
    enableTime: props.time,
    disableMobile: true,
    minuteIncrement: 1,
    mode: props.range ? 'range' : 'single',
    dateFormat: props.time ? 'Y-m-d H:i' : 'Y-m-d',
    altInput: true,
    altFormat: (props.range ? shortAltFormat : fullAltFormat) + (props.time ? ' h:i K' : ''),
    minDate: props.minDate ? props.minDate : (props.futureOnly ? 'today' : null),
    maxDate: props.maxDate ? props.maxDate : null
})

const valueProxy = ref(null)
const id = ref('')

function change (e) {
    const date = props.range ? e.target.value.split(' to ') : new Date(e.target.value)
    emit('update:modelValue', date)
}

onMounted(() => {
    id.value = 'dateinput' + (Math.random() + 1).toString(36).substring(7)
    valueProxy.value = props.range ? props.modelValue : new Date(props.modelValue)
})
</script>

<template>
    <div>
        <div class="relative w-full">
            <InputLabel
                v-if="label"
                :for-input="id">
                {{ label }}
            </InputLabel>

            <flat-pickr
                v-model="valueProxy"
                :config="options"
                :placeholder="placeholder"
                :class="[
                    range ? 'bg-slate-950/5 font-semibold text-center py-1.5 border-transparent text-slate-950' : 'py-2 bg-white border-primary/20 '
                ]"
                class="w-full cursor-pointer rounded-lg border px-3 text-sm transition-colors focus:border-primary focus:outline-none dark:bg-slate-800 dark:text-white dark:disabled:text-white/50"
                @change="change" />
        </div>
        <div v-if="error">
            {{ error }}
        </div>
    </div>
</template>
