<script setup>
import patternImage from '~/app/patterns/logo.svg'
import InputAdvancedImageSelector from '@/Components/Input/InputAdvancedImageSelector.vue'
import { ref } from 'vue'
import { useForm } from '@inertiajs/vue3'
import { CircleStencil } from 'vue-advanced-cropper'
import { CameraIcon, TrashIcon } from '@heroicons/vue/24/outline'
import { useHasFileInput } from '@/Composables/useHasFileInput.js'

const props = defineProps({
    defaultCover: String,
    removable: {
        type: Boolean,
        default: false
    }
})

const form = useForm({
    image: null
})

const emit = defineEmits(['selected', 'removed', 'deleted'])

const fileSelector = ref(null)
const selectedImage = ref(null)
const preview = ref(props.defaultAvatar)
const loading = ref(false)
const {
    isDragover,
    drop,
    dragover,
    dragleave,
    removed
} = useHasFileInput(fileSelector, form, selectedImage)

function removeImage (index) {
    removed(index)
    emit('removed')
}

function imageUploaded (file) {
    form.image = file[0]
    loading.value = false
    emit('selected', file[0])
}

function deleteCover () {
    emit('deleted')
    preview.value = null
}

function previews (files) {
    preview.value = files[0]
}

function uploading () {
    loading.value = true
}
</script>
<template>
    <div>
        <div
            :class="!preview && !defaultCover ? '' : 'bg-cover bg-center bg-no-repeat '"
            :style="`background-image:url('${preview ? preview : (defaultCover ? defaultCover : patternImage)}')`"
            class="relative w-full aspect-[2/1] bg-secondary md:aspect-[4/1]"
            @dragleave="dragleave"
            @dragover="dragover"
            @drop="drop">
            <div
                :class="isDragover ? 'z-30 opacity-100' : 'scale-100 opacity-0'"
                class="pointer-events-none absolute top-0 left-0 flex h-full w-full items-center justify-center rounded-t-2xl border backdrop-blur transition-all border-primary bg-primary/50">
                <span class="text-white">Drop image here</span>
            </div>

            <InputAdvancedImageSelector
                :key="selectedImage"
                :cropping-compontent="CircleStencil"
                :displayed-image="preview ? preview : defaultCover"
                :removable="removable"
                :selected-file="selectedImage"
                cropping-style="rectangle"
                @removed="removeImage"
                @selected="previews"
                @uploaded="imageUploaded"
                @uploading="uploading">
                <template #trigger>
                    <div
                        v-if="!isDragover"
                        v-tooltip="'Change cover'"
                        class="absolute right-14 bottom-4 flex aspect-square items-center gap-2 rounded-full px-2 text-xs text-white transition-all bg-slate-950/50 py-1.5 hover:bg-slate-950">
                        <CameraIcon class="w-4" />
                    </div>
                </template>
                <template #deleteTrigger>
                    <button
                        v-if="defaultCover && !isDragover"
                        v-tooltip="'Remove cover'"
                        class="absolute right-4 bottom-4 flex aspect-square items-center gap-2 rounded-full px-2 text-xs text-white transition-all bg-slate-950/50 py-1.5 hover:bg-slate-950"
                        type="button"
                        @click="deleteCover">
                        <TrashIcon class="w-4" />
                    </button>
                </template>
            </InputAdvancedImageSelector>
        </div>
    </div>
</template>
