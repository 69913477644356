<script setup>
import Icon from '@/Components/Icon'

import { usePageProps } from '@/Composables/usePageProps.ts'

defineProps({
    labels: {
        type: Boolean,
        default: false
    }
})

const items = [
    {
        name: 'Facebook',
        url: usePageProps().config.social_media.facebook.url,
        icon: Icon.Facebook,
        colours: 'bg-[#1778F2]'
    },
    {
        name: 'Instagram',
        url: usePageProps().config.social_media.instagram.url,
        icon: Icon.Instagram,
        colours: 'bg-[#bc2a8d]'
    },
    {
        name: 'Twitter/X',
        url: usePageProps().config.social_media.twitter.url,
        icon: Icon.TwitterX,
        colours: 'bg-black'
    },
    // {
    //     name: 'Discord',
    //     url: usePageProps().config.social_media.discord.url,
    //     icon: Icon.Discord,
    //     colours: 'bg-[#5864f2]'
    // },
    {
        name: 'Threads',
        url: usePageProps().config.social_media.threads.url,
        icon: Icon.Threads,
        colours: 'bg-black'
    }
]
</script>

<template>
    <ul class="flex w-full justify-around">
        <li
            v-for="item in items"
            :key="item.url"
            class="relative flex flex-col">
            <a
                :class="item.colours"
                :href="item.url"
                target="_blank"
                class="aspect-square w-full translate-y-0 overflow-hidden rounded-full text-white transition-all p-2.5 group hover:-translate-y-1">
                <div
                    class="pointer-events-none absolute top-0 left-0 h-full w-full bg-white opacity-0 transition group-hover:opacity-10" />
                <div
                    class="relative flex h-full w-full items-center justify-center"
                    itemprop="sameAs">
                    <span class="sr-only">Link to {{ item.name }} page</span>
                    <component
                        :is="item.icon"
                        class="w-6" />
                </div>
            </a>
            <div
                v-if="labels"
                class="absolute top-full left-1/2 mt-1 -translate-x-1/2 text-xs">
                {{ item.name }}
            </div>
        </li>
    </ul>
</template>
