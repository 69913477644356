import { h } from 'vue'
import RatingRenderer from '@/Components/Ratings/RatingRenderer.vue'

export function useParseRatingsContent (content: string) {
    const result: (string | ReturnType<typeof h>)[] = []
    const div = document.createElement('div')
    div.innerHTML = content

    div.childNodes.forEach(node => {
        if (node.nodeType === Node.TEXT_NODE) {
            const textContent = node.textContent?.trim()
            if (textContent) {
                result.push(textContent)
            }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            const element = node as Element

            if (element.tagName === 'P') {
                const matches = element.innerHTML.match(/\[rating:([^\]]+)]/)
                if (matches) {
                    const uuid = matches[1]
                    result.push(h(RatingRenderer, { uuid }))
                } else {
                    result.push(element.outerHTML)
                }
            } else {
                result.push(element.outerHTML)
            }
        }
    })

    return result
}
