<script setup lang="ts">
import RatingType from '@/Enums/RatingType'
import BaseRatingForm from '@/Components/Ratings/BaseRatingForm.vue'
import type RatingFormComponent from '@/Types/Ratings/RatingFormComponent'
import { useForm } from '@inertiajs/vue3'
import { onMounted, ref, watch } from 'vue'
import type { RatingForm } from '@/Types/Ratings/RatingForm'

const props = defineProps<RatingFormComponent>()

interface SliderValue {
    id: number | string;
    value: number;
    min: string;
    max: string;
}

const form = useForm<RatingForm<SliderValue>>({
    rateable_type: props.rateableType,
    rateable_id: props.rateable.id,
    type: RatingType.SLIDER as RatingType,
    title: '',
    description: '',
    meta: {
        values: [
            { id: 0, value: 50, min: '', max: '' }
        ]
    }
})

function autoresize (element: HTMLTextAreaElement | null) {
    if (!element) return
    element.style.height = '38px'
    element.style.height = `${element.scrollHeight}px`
}

const minTextarea = ref<HTMLTextAreaElement | null>(null)
const maxTextarea = ref<HTMLTextAreaElement | null>(null)

watch(() => form.meta, () => {
    autoresize(minTextarea.value)
    autoresize(maxTextarea.value)
}, { deep: true })

onMounted(() => {
    autoresize(minTextarea.value)
    autoresize(maxTextarea.value)
})

window.addEventListener('resize', () => {
    autoresize(minTextarea.value)
    autoresize(maxTextarea.value)
})
</script>

<template>
    <BaseRatingForm
        v-model:form="form"
        :force-column="true"
        :default-value="{title: '', value: 50}">
        <template #element="{ element }">
            <div class="relative h-3 w-full rounded-full bg-gradient-to-r from-red-500 to-green-500 group lg:-mt-4">
                <div
                    :style="{ left: `${element.value}%` }"
                    class="pointer-events-none absolute -translate-x-1/2 rounded-lg bg-slate-900 px-4 py-2 text-xs text-white opacity-0 transition-all bottom-[250%] group-hover:opacity-100 dark:bg-black">
                    {{ element.value }}%
                </div>
                <div
                    v-for="n in 9"
                    :key="n"
                    class="absolute top-0 h-full -translate-x-1/2 bg-white w-[2px]"
                    :style="`left:${n*10}%;`" />
                <input
                    v-model="element.value"
                    type="range"
                    value="50"
                    class="absolute top-0 left-0 h-3 w-full appearance-none bg-transparent slider-control focus:outline-none">
            </div>
            <div class="mt-2 flex items-start justify-between">
                <div class="max-w-1/3">
                    <textarea
                        ref="minTextarea"
                        v-model="element.min"
                        placeholder="Label #1"
                        class="mx-auto block w-full resize-none overflow-hidden break-words bg-transparent text-left text-lg font-bold outline-none font-heading"
                        type="text" />
                </div>
                <div class="max-w-1/3">
                    <textarea
                        ref="maxTextarea"
                        v-model="element.max"
                        class="mx-auto block w-full resize-none overflow-hidden break-words bg-transparent text-right text-lg font-bold outline-none font-heading"
                        placeholder="Label #2"
                        type="text" />
                </div>
            </div>
        </template>
        <template #actions>
            <slot name="actions" />
        </template>
    </BaseRatingForm>
</template>

<style scoped>
.slider-control::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 1.75rem;
    background: var(--colors-primary);
    width: 0.625rem;
    border: 2px solid #FFFFFF;
    border-radius: 0.5rem;
}
</style>
