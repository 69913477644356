<script setup>

import ErrorText from '@/Components/UI/ErrorText.vue'
import InputLabel from '@/Components/Input/InputLabel.vue'
import { onMounted, ref, useSlots } from 'vue'
import { XMarkIcon } from '@heroicons/vue/24/solid'

const props = defineProps({
    modelValue: String,
    iconClasses: String,
    label: String,
    autocomplete: String,
    max: [String, null],
    step: [String, null],
    min: [String, null],
    inputId: [String, null],
    error: {
        type: [String, null],
        default: null
    },
    hasAutofocus: {
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
        default: ''
    },
    inputType: {
        type: String,
        default: 'text'
    },
    clearable: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    }
})

const slots = useSlots()

const emit = defineEmits(['update:modelValue', 'focus', 'blur'])

const input = ref(null)
const id = ref(props.inputId)

function focus () {
    input.value.focus()
}

defineExpose({
    focus
})

onMounted(() => {
    if (!props.inputId) {
        id.value = 'input' + (Math.random() + 1).toString(36).substring(7)
    }
    if (props.hasAutofocus) {
        input.value.focus()
    }
})
</script>

<template>
    <div>
        <div class="relative group">
            <InputLabel
                v-if="label"
                :for-input="id">
                {{ label }}
            </InputLabel>
            <div class="relative">
                <div
                    v-if="slots.icon"
                    class="absolute bottom-0 left-1 flex aspect-square h-full items-center justify-center">
                    <div :class="iconClasses">
                        <slot name="icon" />
                    </div>
                </div>
                <div
                    v-if="clearable && modelValue"
                    class="absolute right-1 bottom-0 flex aspect-square h-8 items-center justify-center mb-[3px]">
                    <button
                        v-tooltip="'Clear'"
                        class="text-slate-900/75 hover:text-primary focus:text-primary focus:outline-none dark:text-white/75"
                        type="button"
                        @click="emit('update:modelValue', '')">
                        <span class="sr-only">Clear input</span>
                        <XMarkIcon class="w-4" />
                    </button>
                </div>
                <input
                    :id="id"
                    ref="input"
                    :autocomplete="autocomplete"
                    :class="[error ? 'border-red-500' : 'border-secondary/10', slots.icon ? 'pl-10' : '', clearable ? 'pr-10' : '']"
                    :disabled="disabled"
                    :max="max"
                    :min="min"
                    :placeholder="placeholder"
                    :step="step"
                    :type="inputType ? inputType : 'text'"
                    :value="modelValue"
                    class="w-full rounded-lg border px-3 py-2 text-sm transition-colors border-primary/20 focus:border-primary focus:outline-none dark:bg-slate-800 dark:text-white dark:disabled:text-white/50"
                    @blur="emit('blur')"
                    @focus="emit('focus')"
                    @input="emit('update:modelValue', $event.target.value)">
            </div>
        </div>
        <ErrorText
            v-if="error"
            :error="error" />
    </div>
</template>
