<script setup lang="ts">
import type Grade from '@/Types/Ratings/Grade'
import GradeColourMap from '@/Configs/GradeColourMap.ts'
import RatingContainer from '@/Components/Ratings/RatingContainer.vue'
import { type PropType } from 'vue'
import type { ArticleRating } from '@/Types/ArticleRating'

interface valueItem {
    value: Grade;
    title: string;
}

interface meta {
    values: valueItem[],
}

defineProps({
    rating: Object as PropType<ArticleRating<meta>>
})

function getBaseGrade (grade: string): string {
    return grade.replace(/[+-]/, '')
}
</script>

<template>
    <RatingContainer :rating>
        <div
            v-for="value in rating.meta.values"
            :key="value.value"
            class="flex w-full flex-col justify-center p-4 text-center"
            :class="[GradeColourMap[getBaseGrade(value.value)].text, {
                'w-full': rating.meta.values.length === 1,
                'md:w-1/2': rating.meta.values.length === 2,
                'md:w-1/3': rating.meta.values.length === 3,
                'md:w-1/4': rating.meta.values.length === 4,
                'md:w-1/5': rating.meta.values.length === 5,
            }]">
            <div
                class="text-7xl leading-none font-heading">
                {{ value.value }}
            </div>
            <h3
                v-if="value.title"
                class="mt-2 block w-full break-words text-lg font-bold leading-5 font-heading">
                {{ value.title }}
            </h3>
        </div>
    </RatingContainer>
</template>
