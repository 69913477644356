<script setup lang="ts">
import type { PropType } from 'vue'

const colourMap = {
    gray: 'bg-gray-100 text-gray-600',
    red: 'bg-red-100 text-red-700',
    yellow: 'bg-yellow-100 text-yellow-800',
    green: 'bg-green-100 text-green-700',
    blue: 'bg-blue-100 text-blue-700',
    indigo: 'bg-indigo-100 text-indigo-700',
    purple: 'bg-purple-100 text-purple-700',
    primary: 'bg-primary/10 text-primary',
    pink: 'bg-pink-100 text-pink-700'
}

defineProps({
    colour: {
        type: String as PropType<keyof typeof colourMap>,
        default: 'gray'
    },
    text: {
        type: [String, Number, null],
        default: ''
    },
    small: {
        type: Boolean,
        default: false
    }
})
</script>

<template>
    <span
        :class="[
            `items-center rounded-full text-xs font-medium`,
            small ? 'py-[2px] px-1.5' : 'py-1 px-2',
            colourMap[colour]
        ]">
        {{ text }}
    </span>
</template>
