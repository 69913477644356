<script setup lang="ts">
import * as UserPermission from '@/Enums/UserPermission'
import Status from '@/Enums/Status.ts'
import Button from '@/Components/UI/Button.vue'
import useEmitter from '@/Composables/useEmitter'
import Dropdown from '@/Components/UI/Dropdown.vue'
import { ref } from 'vue'
import { router } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { usePageProps } from '@/Composables/usePageProps.ts'
import type { DropDownItemType } from '@/Types/DropDownItem'
import { usePermissions } from '@/Composables/usePermissions'
import {
    EyeSlashIcon,
    BackspaceIcon,
    PencilIcon,
    TrashIcon,
    ArrowUturnLeftIcon,
    EllipsisVerticalIcon,
    ArrowPathRoundedSquareIcon
} from '@heroicons/vue/24/outline'

const props = defineProps({
    article: Object,
    viewData: Object
})

function restoreArticle () {
    router.patch(useRoute('articles.restore', props.article))
}

const canDelete = props.article.status.name !== Status.TRASHED && (usePermissions(UserPermission.DELETE_ANY_ARTICLE) ||
    (props.article.user.id === usePageProps().auth.user.id && usePermissions(UserPermission.DELETE_OWN_ARTICLE)))

const canPermanentlyDelete = props.article.status.name === Status.TRASHED && (usePermissions(UserPermission.DELETE_ANY_ARTICLE) ||
    (props.article.user.id === usePageProps().auth.user.id && usePermissions(UserPermission.DELETE_OWN_ARTICLE)))

const canRestore = props.article.status.name === Status.TRASHED && (usePermissions(UserPermission.RESTORE_ANY_ARTICLE) ||
    (props.article.user.id === usePageProps().auth.user.id && usePermissions(UserPermission.RESTORE_OWN_ARTICLE)))

const canEdit = [Status.DRAFTS, Status.REJECTED].includes(props.article.status.name) && (usePermissions(UserPermission.EDIT_ANY_ARTICLE) ||
    (props.article.user.id === usePageProps().auth.user.id && usePermissions(UserPermission.EDIT_OWN_ARTICLE)))

const canUnpublish = [Status.PUBLISHED].includes(props.article.status.name) && (usePermissions(UserPermission.UNPUBLISH_ANY_ARTICLE) ||
    (props.article.user.id === usePageProps().auth.user.id && usePermissions(UserPermission.UNPUBLISH_OWN_ARTICLE)))

const canWithdraw = [Status.APPROVED, Status.SUBMITTED].includes(props.article.status.name) && (props.article.user.id === usePageProps().auth.user.id)

const actionButtons: DropDownItemType[] = [
    {
        url: useRoute('articles.edit', props.article),
        icon: PencilIcon,
        title: 'Edit',
        if: canEdit
    },
    {
        action: () => useEmitter.emit('article:withdraw', props.article),
        icon: ArrowUturnLeftIcon,
        title: 'Withdraw',
        activeClasses: 'bg-red-100 text-red-800 border-current',
        if: canWithdraw
    },
    {
        action: () => useEmitter.emit('article:unpublish', props.article),
        icon: EyeSlashIcon,
        title: 'Unpublish',
        activeClasses: 'bg-red-100 text-red-800 border-current',
        if: canUnpublish
    },
    {
        action: () => useEmitter.emit('article:delete', props.article),
        icon: BackspaceIcon,
        title: 'Trash',
        activeClasses: 'bg-red-500 text-white border-current',
        if: canDelete
    },
    {
        action: () => restoreArticle(),
        icon: ArrowPathRoundedSquareIcon,
        title: 'Restore',
        activeClasses: 'bg-green-100 text-green-800 border-current',
        if: canRestore
    },
    {
        action: () => useEmitter.emit('article:delete:permanent', props.article),
        icon: TrashIcon,
        title: 'Delete permanently',
        activeClasses: 'bg-red-500 text-white border-current',
        if: canPermanentlyDelete
    }
]

const dropdown = ref(null)

// function handleClick (text) {
//     const action = actionButtons.find(button => button.title === text)
//
//     dropdown.value.close()
//
//     if (!action) return
//     if (action.href) return
//
//     action.action()
// }
</script>

<template>
    <div>
        <Dropdown
            ref="dropdown"
            :items="actionButtons">
            <template #toggle>
                <button class="transition-all text-slate-950/50 hover:text-slate-950">
                    <EllipsisVerticalIcon class="w-6" />
                </button>
            </template>
        </Dropdown>
    </div>
</template>
