<script setup lang="ts">
import FsLightbox from '@/Plugins/fslightbox/v3'
import { type PropType, ref } from 'vue'
import type { Post } from '@/Types/Models/Post'
import { useImageOptimise } from '@/Composables/useImageOptimise'

const props = defineProps({
    post: Object as PropType<Post>
})

const images = props.post.images

const showImageLightbox = ref<boolean>(false)
const imageLightboxSelected = ref<number>(0)

function openLightboxOnSlide (number: number) {
    number = number + 1
    imageLightboxSelected.value = number
    showImageLightbox.value = !showImageLightbox.value
}
</script>
<template>
    <div>
        <FsLightbox
            v-if="images && images.length"
            :slide="imageLightboxSelected"
            :thumbs="images.map(image => useImageOptimise(image.small))"
            :sources="images.map(image => useImageOptimise(image.original))"
            :toggler="showImageLightbox" />
        <div class="w-full">
            <div
                v-if="images.length === 1"
                class="flex gap-2">
                <div
                    v-for="image in images"
                    :key="image.uuid"
                    class="aspect-video w-full cursor-pointer"
                    @click="openLightboxOnSlide(0)">
                    <img
                        v-lazy="image.large"
                        :alt="`Image #1`"
                        class="h-full w-full rounded-2xl object-cover">
                </div>
            </div>
            <div
                v-if="images.length === 2"
                class="flex gap-2">
                <div
                    v-for="(image, index) in images"
                    :key="image.uuid"
                    class="aspect-square w-1/2 cursor-pointer"
                    @click="openLightboxOnSlide(index)">
                    <img
                        v-lazy="image.medium"
                        :alt="`Image #` + (index + 1)"
                        :class="{
                            'rounded-l-2xl': index === 0,
                            'rounded-r-2xl': index === 1,
                        }"
                        class="h-full w-full object-cover">
                </div>
            </div>
            <div
                v-if="images.length === 3"
                class="flex gap-2">
                <div
                    class="w-2/3 cursor-pointer"
                    @click="openLightboxOnSlide(0)">
                    <img
                        v-lazy="images[0].large"
                        :alt="`Image #1`"
                        class="h-full w-full rounded-l-2xl object-cover">
                </div>
                <div class="flex w-1/3 flex-col gap-2">
                    <div
                        v-for="(image, index) in images.slice(1)"
                        :key="image.uuid"
                        class="aspect-square w-full cursor-pointer"
                        @click="openLightboxOnSlide(index)">
                        <img
                            v-lazy="image.medium"
                            :alt="`Image #` + (index + 1)"
                            :class="{
                                'rounded-tr-2xl': index === 0,
                                'rounded-br-2xl': index === 1,
                            }"
                            class="h-full w-full object-cover">
                    </div>
                </div>
            </div>

            <div
                v-if="images.length > 3"
                class="-mx-1 flex flex-wrap">
                <div
                    v-for="(image, index) in images.slice(0, 4)"
                    :key="image.uuid"
                    class="relative aspect-square w-1/2 cursor-pointer p-1"
                    @click="openLightboxOnSlide(index)">
                    <img
                        v-lazy="image.large"
                        :alt="`Image #` + (index + 1)"
                        :class="{
                            'rounded-tl-2xl': index === 0,
                            'rounded-tr-2xl': index === 1,
                            'rounded-bl-2xl': index === 2,
                            'rounded-br-2xl': index === 3,
                        }"
                        class="h-full w-full object-cover">
                    <div
                        v-if="images.length > 4 && index === 3"
                        class="absolute inset-1 flex aspect-square cursor-pointer items-center justify-center rounded-br-2xl bg-slate-900/75 p-1 text-xl font-semibold text-white transition-all hover:bg-slate-900/80">
                        +{{ images.length - 4 }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
