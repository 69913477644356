<template>
    <div>
        <Listbox
            v-model="selectedProxy"
            class="min-w-44"
            as="div">
            <ListboxLabel>
                <InputLabel
                    v-if="label">
                    {{ label }}
                </InputLabel>
            </ListboxLabel>
            <div class="relative">
                <ListboxButton
                    class="relative w-full cursor-default rounded-lg border bg-white pr-10 pl-3 text-left text-slate-900 py-1.5 border-secondary/10 focus:border-primary focus:outline-0 focus:ring-0 sm:text-sm sm:leading-6">
                    <span class="block truncate">{{ selectedProxy ? selectedProxy.label : (placeholder ? placeholder : '') }}</span>
                    <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                            class="h-5 w-5 text-gray-400"
                            aria-hidden="true" />
                    </span>
                </ListboxButton>

                <transition
                    leave-active-class="transition duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
                    <ListboxOptions
                        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 text-base shadow-sm ring-1 ring-offset-0 ring-secondary/10 focus:outline-none sm:text-sm">
                        <ListboxOption
                            v-for="option in optionsProxy"
                            :key="option.value"
                            v-slot="{ active, selected }"
                            :disabled="option.disabled"
                            as="template"
                            :value="option">
                            <li
                                :class="[
                                    {
                                        'bg-primary text-white': active,
                                        'text-slate-900': !active,
                                        'pr-3 pl-9': displayCheck,
                                        'px-3': !displayCheck
                                    },
                                    'relative cursor-default select-none py-2'
                                ]">
                                <span
                                    :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                                    {{ option.label }}
                                </span>

                                <span
                                    v-if="selected && displayCheck"
                                    :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 left-0 flex items-center pl-1.5']">
                                    <CheckIcon
                                        class="h-5 w-5"
                                        aria-hidden="true" />
                                </span>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
        <ErrorText
            v-if="error"
            class="mt-1"
            :error="error" />
    </div>
</template>

<script setup>
import ErrorText from '@/Components/UI/ErrorText.vue'
import InputLabel from '@/Components/Input/InputLabel.vue'
import { onMounted, ref, watch } from 'vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'

const props = defineProps({
    options: Array,
    label: String,
    displayCheck: {
        type: Boolean,
        default: true
    },
    error: {
        type: String,
        default: null
    },
    placeholder: {
        type: String,
        default: null
    },
    modelValue: [String, Number, null]
})

const emit = defineEmits(['update:modelValue'])

const optionsProxy = ref(props.options)
const selectedProxy = ref(optionsProxy.value.find(option => option.value === props.modelValue))

onMounted(() => {
    if (props.placeholder) {
        optionsProxy.value = [{ label: props.placeholder, value: null, disabled: true }, ...optionsProxy.value]
    }
})

watch(selectedProxy, (newValue) => {
    if (newValue.value === props.modelValue) {
        return
    }

    emit('update:modelValue', newValue.value)
})
</script>
