<script setup lang="ts">
import RatingType from '@/Enums/RatingType'
import RatingCircle from '@/Components/UI/RatingCircle.vue'
import BaseRatingForm from '@/Components/Ratings/BaseRatingForm.vue'
import type RatingFormComponent from '@/Types/Ratings/RatingFormComponent'
import { computed, ref } from 'vue'
import { useForm } from '@inertiajs/vue3'
import type { RatingForm } from '@/Types/Ratings/RatingForm'

const props = defineProps<RatingFormComponent>()

interface NumberValue {
    id: number | string;
    value: string;
    title: string;
}

const form = useForm<RatingForm<NumberValue>>({
    rateable_type: props.rateableType,
    rateable_id: props.rateable.id,
    type: RatingType.NUMBER as RatingType,
    title: '',
    description: '',
    meta: {
        values: [
            { id: 0, value: 0, title: '', percentage: false }
        ]
    }
})

const keyValue = ref(0)
const valuesCount = computed(() => form.meta.values.length)

const hasMultipleValues = computed(() => form.meta.values?.length > 1)
const width = computed(() =>
    hasMultipleValues.value ? (valuesCount.value > 2 ? 120 : 140) : 160
)
</script>

<template>
    <BaseRatingForm
        v-model:form="form"

        :default-value="{title: '', value: 0}"

        @reset="keyValue++">
        <template #element="{ element }">
            <RatingCircle
                :key="element.id + '-' + valuesCount + '-' + keyValue"
                v-model:value="element.value"
                v-model:percentage="element.percentage"
                :width="width"
                :has-input="true"
                :score="element.value" />
        </template>
        <template #actions>
            <slot name="actions" />
        </template>
    </BaseRatingForm>
</template>
