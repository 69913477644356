<script setup>
import Button from '@/Components/UI/Button.vue'
import DashboardLayout from '@/Layouts/DashboardLayout.vue'
import EmptyState from '@/Components/Global/EmptyState.vue'
import ArticleCard from '@/Components/Article/ArticleCard.vue'
import DashboardTitle from '@/Components/Dashboard/DashboardTitle.vue'
import { router } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'

defineProps({
    submitted: Array,
    in_review: Array,
    errors: Object
})

defineOptions({ layout: DashboardLayout })

function revert (article) {
    router.post(useRoute('articles.review.revert', article))
}
</script>

<template>
    <DashboardTitle
        title="Submitted articles"
        description="Welcome to your dashboard." />

    <div class="pb-12">
        <div
            v-if="submitted.length"
            class="-mx-4 mt-8 flex flex-wrap">
            <div
                v-for="article in submitted"
                :key="article.uuid"
                class="flex w-1/4 shrink-0 items-start p-4">
                <ArticleCard
                    :url-override="useRoute('articles.review.view', article)"
                    :article="article"
                    class="w-full" />
            </div>
        </div>
        <div v-else>
            <EmptyState
                title="No articles submitted"
                description="No articles have been submitted for review" />
        </div>

        <div
            v-if="in_review.length"
            class="mt-24">
            <DashboardTitle
                title="Articles in review"
                description="These articles are already in review." />
            <div
                v-if="in_review.length"
                class="-mx-4 mt-8 flex flex-wrap">
                <div
                    v-for="article in in_review"
                    :key="article.uuid"
                    class="flex w-1/4 shrink-0 flex-col items-start p-4">
                    <ArticleCard
                        :url-override="useRoute('articles.review.view', article)"
                        :article="article"
                        class="w-full" />
                    <div class="mt-4 w-full">
                        <Button
                            variant="primary-light"
                            class="w-full"
                            @click="revert(article)">
                            Stop reviewing
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
