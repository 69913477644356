<template>
    <svg

        role="presentation"
        viewBox="0 0 18 18">
        <g
            clip-rule="evenodd"
            fill-rule="evenodd">
            <path
                d="M17.64 9.20468c0-.63818-.0573-1.25182-.1636-1.84091H9v3.48133h4.8436c-.2086 1.125-.8427 2.0782-1.7959 2.7164v2.2582h2.9087c1.7018-1.5668 2.6836-3.8741 2.6836-6.61502Z"
                fill="#4285F4" />
            <path
                d="M8.99976 18c2.43004 0 4.46724-.8059 5.95634-2.1805l-2.9086-2.2582c-.8059.54-1.8368.8591-3.04774.8591-2.34409 0-4.32818-1.5832-5.03591-3.7104H.957031v2.3318C2.43794 15.9831 5.48158 18 8.99976 18Z"
                fill="#34A853" />
            <path
                d="M3.96409 10.7101c-.18-.54-.28227-1.11685-.28227-1.71003s.10227-1.17.28227-1.71V4.95825H.957273C.347727 6.17325 0 7.5478 0 9.00007c0 1.45223.347727 2.82683.957273 4.04183l3.006817-2.3318Z"
                fill="#FBBC05" />
            <path
                d="M8.99976 3.57955c1.32134 0 2.50774.45409 3.44044 1.3459l2.5814-2.58136C13.4629.891818 11.4257 0 8.99976 0 5.48158 0 2.43794 2.01682.957031 4.95818L3.96385 7.29c.70773-2.12727 2.69182-3.71045 5.03591-3.71045Z"
                fill="#EA4335" />
        </g>
    </svg>
</template>
