import { useRoute } from '@/Composables/useRoute.ts'
import { usePageProps } from '@/Composables/usePageProps.ts'

export function useLinkifyString (content: string) {
    if (!content) {
        return ''
    }

    const urlPattern = /(\b(https?|ftp|file):\/\/[\w!#%&+,./:;=?@|~-]*[\w#%&+/=@|~-])|(\b[\w-]+(\.[\w-]+)+(:\d+)?(\/[\w!#%&+,./:;=?@|~-]*)?(\?[\w!#%&+,./:;=?@|~-]*)?(#[\w!#%&+,./:;=?@|~-]*)?)/gi

    return content.replace(urlPattern, (match, p1, p2, p3) => {
        const isUrl = p1 || p3
        const href = p1 || 'https://' + p3
        const url = useRoute('link-out', { url: href, redirectToken: usePageProps().csrf_token })
        if (isUrl) {
            return `<a href="${url}" rel="noopener noreferrer" class="text-primary hover:underline" target="_blank">${p1 || p3}</a>`
        }
        return match
    })
}
