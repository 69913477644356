<script setup lang="ts">
import Card from '@/Components/UI/Card.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import { computed, type PropType } from 'vue'
import type { ArticleRating } from '@/Types/ArticleRating'

const props = defineProps({
    rating: Object as PropType<ArticleRating<any>>,
    maxTitleWidth: {
        type: String,
        default: 'w-full md:w-8/12'
    },
    forceColumn: {
        type: Boolean,
        default: false
    }
})

const hasTitle = computed(() => props.rating.title || props.rating.description)
const hasMultipleValues = computed(() => props.rating.meta.values?.length > 1)
</script>

<template>
    <div>
        <Card padding="p-5 md:p-6">
            <div
                class="flex flex-col items-center gap-4"
                :class="[
                    ((hasTitle && hasMultipleValues) || forceColumn) ? 'md:flex-col' : 'md:flex-row',
                    forceColumn ? 'md:gap-8' : ''
                ]">
                <div
                    v-if="rating.title || rating.description"
                    :class="(hasMultipleValues || forceColumn) ? 'w-full' : maxTitleWidth"
                    class="flex shrink-0 flex-col gap-0">
                    <SectionTitle font-size="text-xl sm:text-2xl">
                        {{ rating.title }}
                    </SectionTitle>
                    <SectionDescription>
                        {{ rating.description }}
                    </SectionDescription>
                </div>
                <div
                    :class="[
                        ((rating.title || rating.description) && !hasMultipleValues && !forceColumn) ? 'justify-end' : 'justify-center',
                        (rating.meta.values.length >= 4 || forceColumn) ? 'flex-wrap divide-none' : 'flex-nowrap divide-background',
                        !forceColumn ? 'sm:flex-row' : ''
                    ]"
                    class="flex w-full flex-col items-start text-slate-900 divide-y sm:divide-y-0 sm:divide-x">
                    <slot />
                </div>
            </div>
        </Card>
    </div>
</template>
