/* eslint-disable-next-line no-unused-vars  */
enum RatingType {
    STAR = 'star',
    NUMBER = 'number',
    SLIDER = 'slider',
    THUMB = 'thumb',
    EMOJI = 'emoji',
    GRADE = 'grade'
}

export default RatingType
