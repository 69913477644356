<script setup lang="ts">
import { type PropType } from 'vue'
import { Link } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Article } from '@/Types/Models/Article'
import { useContrast } from '@/Composables/useContrast'
import type { Category } from '@/Types/Models/Category'
import { useHexToRgb } from '@/Composables/useHexToRgb.js'

const props = defineProps({
    article: Object as PropType<Article>,
    category: Object as PropType<Category>,
    size: {
        type: String,
        default: 'small'
    }
})

const textColour = props.category ? useContrast(props.category.metadata?.colour, 'text-black/75', 'text-white') : null
const bgColour = props.category ? `background-color:rgba(${useHexToRgb(props.category.metadata?.colour ?? 'var(--colors-primary)')}, var(--tw-bg-opacity));` : null
const icon =
    props.category && (props.category.icon_dark || props.category.icon)
        ? useContrast(props.category.metadata.colour ?? 'var(--colors-tertiary)', props.category.icon_dark, props.category.icon)
        : null
</script>

<template>
    <div>
        <Link
            v-if="category"
            :href="useRoute('articles.categorised', category)"
            class="z-10 bg-white font-semibold transition-colors group/category">
            <div
                :class="[size === 'small' ? 'text-xs rounded-md py-0.5 px-2' : 'text-sm rounded-full py-1 px-2.5', textColour]"
                :style="bgColour"
                class="flex select-none items-center transition-colors space-x-1">
                <img
                    v-if="icon && size !== 'small'"
                    :alt="`Icon for ${category.singular_title}`"
                    :src="icon"
                    class="flex"
                    height="14"
                    width="14">
                <span style="text-shadow: 0px 0px 2px rgba(0,0,0,0.5)">{{ category.singular_title }}</span>
            </div>
        </Link>
        <div
            v-else
            class="z-10 rounded bg-white font-semibold transition-colors group/category">
            <div
                :class="[size === 'small' ? 'text-xs' : 'text-sm']"
                class="flex select-none items-center rounded px-2 py-1 transition-colors space-x-1 bg-secondary/20">
                <span>Uncategorised</span>
            </div>
        </div>
    </div>
</template>
