import { HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/vue/24/solid'
import type { FunctionalComponent } from 'vue'

interface IconClasses {
    text: string;
    bg: string;
    icon: string | null;
}

interface ThumbIconEntry {
    component: FunctionalComponent;
    classes: IconClasses;
}

interface ThumbIconMapType {
    up: ThumbIconEntry;
    down: ThumbIconEntry;
    neutral: ThumbIconEntry;
}

const ThumbIconMap: ThumbIconMapType = {
    up: {
        component: HandThumbUpIcon,
        classes: {
            text: 'text-green-500',
            bg: 'bg-green-100',
            icon: null
        }
    },
    down: {
        component: HandThumbDownIcon,
        classes: {
            text: 'text-red-500',
            bg: 'bg-red-100',
            icon: null
        }
    },
    neutral: {
        component: HandThumbUpIcon,
        classes: {
            text: 'text-orange-500',
            bg: 'bg-orange-100',
            icon: 'rotate-90'
        }
    }
}

export default ThumbIconMap
