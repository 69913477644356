<script setup lang="ts">
import RatingCircle from '@/Components/UI/RatingCircle.vue'
import RatingContainer from '@/Components/Ratings/RatingContainer.vue'
import { computed, type PropType } from 'vue'
import type { ArticleRating } from '@/Types/ArticleRating'

interface valueItem {
    value: number;
    title: string;
    percentage: boolean;
}

interface meta {
    values: valueItem[],
}

const props = defineProps({
    rating: Object as PropType<ArticleRating<meta>>
})

const valuesCount = computed(() => props.rating.meta.values.length)
const hasMultipleValues = computed(() => valuesCount.value > 1)
</script>

<template>
    <RatingContainer :rating>
        <div
            v-for="value in rating.meta.values"
            :key="value.value"
            :class="{
                'w-full': rating.meta.values.length === 1,
                'md:w-1/2': rating.meta.values.length === 2,
                'md:w-1/3 my-2': rating.meta.values.length >= 3,
            }"
            class="flex w-full justify-center p-4">
            <RatingCircle
                v-model:value="value.value"
                :width="(hasMultipleValues ? (valuesCount > 2 ? 120 : 140) : 160)"
                :title="value.title"
                :percentage="value.percentage" />
        </div>
    </RatingContainer>
</template>
