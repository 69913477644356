<script setup lang="ts">
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import { useSlots } from 'vue'

defineProps({
    title: String,
    description: String
})

const slots = useSlots()
</script>

<template>
    <div
        :class="slots.actions ? 'md:border-b border-slate-900/10 md:pb-4' : 'border-b border-slate-900/10 pb-4'"
        class="mb-8 flex flex-col justify-between gap-4 dark:border-white/10 md:flex-row md:items-center">
        <div
            :class="slots.actions ? 'border-b border-slate-900/10 pb-4 md:border-b-0 md:pb-0' : ''"
            class="flex flex-col">
            <SectionTitle v-if="title">
                {{ title }}
            </SectionTitle>
            <SectionDescription v-if="description">
                {{ description }}
            </SectionDescription>
        </div>
        <div
            v-if="slots.actions"
            class="flex shrink-0 justify-end">
            <slot name="actions" />
        </div>
    </div>
</template>
