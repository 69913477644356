import { ArticleAccess } from '@/Enums/ArticleAccess.ts'
import { GlobeAltIcon, LockClosedIcon, UserGroupIcon, UserIcon, LinkIcon } from '@heroicons/vue/24/outline'
import {
    GlobeAltIcon as GlobeAltIconSolid,
    LockClosedIcon as LockClosedIconSolid,
    UserGroupIcon as UserGroupIconSolid,
    UserIcon as UserIconSolid,
    LinkIcon as LinkIconSolid
} from '@heroicons/vue/24/solid'

export function useArticleAccessInfo (key: string = ArticleAccess.ALL, solid: boolean = false) {
    const map = [
        {
            type: ArticleAccess.ALL,
            icon: solid ? GlobeAltIconSolid : GlobeAltIcon,
            title: 'Everyone',
            tooltip: ''
        },
        {
            type: ArticleAccess.PASSWORD,
            icon: solid ? LockClosedIconSolid : LockClosedIcon,
            title: 'Password',
            tooltip: 'This article is password protected.'
        },
        {
            type: ArticleAccess.SUBSCRIBERS,
            icon: solid ? UserGroupIconSolid : UserGroupIcon,
            title: 'Subscribers',
            tooltip: 'This article is only available to subscribers.'
        },
        {
            type: ArticleAccess.AUTHOR,
            icon: solid ? UserIconSolid : UserIcon,
            title: 'Just me',
            tooltip: 'This article is only available to you.'
        },
        {
            type: ArticleAccess.LINKED,
            icon: solid ? LinkIconSolid : LinkIcon,
            title: 'Link only',
            tooltip: 'This article is only available via a direct link.'
        }
    ]

    return map.find(item => item.type === key) || map[0]
}
