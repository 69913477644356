<script setup>
import 'vue-advanced-cropper/dist/style.css'
import InputAdvancedImageSelector from '@/Components/Input/InputAdvancedImageSelector.vue'
import { ref } from 'vue'
import { useForm } from '@inertiajs/vue3'
import { CameraIcon } from '@heroicons/vue/20/solid'
import { useHasFileInput } from '@/Composables/useHasFileInput.js'

const props = defineProps({
    defaultAvatar: String,
    removable: {
        type: Boolean,
        default: false
    }
})

const form = useForm({
    image: null
})

const emit = defineEmits(['selected', 'removed'])

const fileSelector = ref(null)
const selectedImage = ref(null)
const preview = ref(props.defaultAvatar)
const loading = ref(false)
const {
    isDragover,
    drop,
    dragover,
    dragleave,
    removed
} = useHasFileInput(fileSelector, form, selectedImage)

function removeImage (index) {
    removed(index)
    emit('removed')
}

function imageUploaded (file) {
    form.image = file[0]
    loading.value = false
    emit('selected', file[0])
}

function previewImage (files) {
    preview.value = files[0]
}

function uploading () {
    loading.value = true
}
</script>
<template>
    <div>
        <div
            :style="`background-image:url('${preview ?? defaultAvatar}')`"
            class="relative aspect-square rounded-full bg-cover bg-center bg-no-repeat"
            @dragleave="dragleave"
            @dragover="dragover"
            @drop="drop">
            <div
                :class="isDragover ? 'z-0 opacity-100' : 'scale-100 opacity-0'"
                class="pointer-events-none absolute top-0 left-0 flex h-full w-full items-center justify-center rounded-full border text-center backdrop-blur transition-all border-primary bg-primary/75">
                <span class="text-xs text-white leading-0">Drop image here</span>
            </div>

            <InputAdvancedImageSelector
                :key="selectedImage"
                :displayed-image="preview ?? defaultAvatar"
                :removable="false"
                :selected-file="selectedImage"
                cropping-style="circle"
                @removed="removeImage"
                @selected="previewImage"
                @uploaded="imageUploaded"
                @uploading="uploading">
                <template #trigger>
                    <div
                        v-if="!isDragover"
                        class="absolute -right-2 -bottom-2 flex items-center justify-center rounded-full border-4 border-white bg-black/25">
                        <div class="flex aspect-square w-8 items-center justify-center rounded-full p-2 bg-primary">
                            <CameraIcon class="w-5 text-white" />
                        </div>
                    </div>
                </template>
            </InputAdvancedImageSelector>
        </div>
    </div>
</template>
