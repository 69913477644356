<script setup>
import * as UserPermission from '@/Enums/UserPermission.js'
import Modal from '@/Components/UI/Modal.vue'
import Button from '@/Components/UI/Button.vue'
import Dropdown from '@/Components/UI/Dropdown.vue'
import PostReportModal from '@/Components/Post/PostReportModal.vue'
import { ref } from 'vue'
import { usePost } from '@/Composables/usePost.js'
import { useRoute } from '@/Composables/useRoute.ts'
import { useToast } from '@/Composables/useToast.ts'
import { usePermissions } from '@/Composables/usePermissions.js'
import { EllipsisVerticalIcon } from '@heroicons/vue/24/outline'
import { useIsAuthedUser } from '@/Composables/useIsAuthedUser.js'

const props = defineProps({
    post: Object
})

const showPinConfirmation = ref(false)
const showDeletionConfirmation = ref(false)
const showReportConfirmation = ref(false)

const canDelete = usePermissions(UserPermission.DELETE_ANY_POST) ||
    (useIsAuthedUser(props.post.user) && usePermissions(UserPermission.DELETE_OWN_POST))

function confirmDelete () {
    showDeletionConfirmation.value = true
}

function confirmReport () {
    showReportConfirmation.value = true
}

function confirmPin () {
    showPinConfirmation.value = true
}

const emit = defineEmits(['deleted', 'reported', 'pinned', 'unpinned'])

function deleteComment () {
    usePost({
        url: useRoute('posts.destroy', { post: props.post }),
        method: 'DELETE',
        onUnauthorized: () => useToast('Unauthorized action', 'fail'),
        onSuccess: response => {
            emit('deleted')
            showDeletionConfirmation.value = false
        }
    })
}

function pinPost () {
    usePost({
        url: useRoute('posts.pin', { post: props.post }),
        method: 'POST',
        onUnauthorized: () => useToast('Unauthorized action', 'fail'),
        onSuccess: response => {
            emit('pinned')
            showPinConfirmation.value = false
        }
    })
}

function unpinPost () {
    usePost({
        url: useRoute('posts.unpin', { post: props.post }),
        method: 'DELETE',
        onUnauthorized: () => useToast('Unauthorized action', 'fail'),
        onSuccess: response => {
            emit('unpinned')
            showPinConfirmation.value = false
        }
    })
}

const items = [
    {
        title: 'Delete',
        if: canDelete,
        component: 'button',
        action: confirmDelete
    },
    {
        title: 'Unpin',
        if: props.post.is_pinned,
        component: 'button',
        action: unpinPost
    },
    {
        title: 'Pin',
        if: !props.post.is_pinned,
        component: 'button',
        action: confirmPin
    },
    {
        title: 'Report',
        component: 'button',
        action: confirmReport
    }
]
</script>

<template>
    <div>
        <div>
            <Dropdown :items="items">
                <template #toggle>
                    <button
                        class="p-2 text-slate-900/75 hover:text-slate-900 focus:text-slate-900 dark:text-white/75 dark:hover:text-white">
                        <span class="sr-only">Open options for post: {{ post.id }}</span>
                        <EllipsisVerticalIcon class="w-5" />
                    </button>
                </template>
            </Dropdown>
        </div>
        <Modal
            :show="showDeletionConfirmation"
            max-width="md"
            @close="showDeletionConfirmation = false">
            <template #title>
                Delete Post
            </template>

            <template #content>
                Are you sure you want to delete this post?
            </template>

            <template #footer>
                <div class="flex w-full flex-row items-center justify-end gap-4">
                    <Button
                        variant="white"
                        @click="showDeletionConfirmation = false">
                        Cancel
                    </Button>

                    <Button
                        variant="danger"
                        @click="deleteComment">
                        Delete
                    </Button>
                </div>
            </template>
        </Modal>

        <Modal
            :show="showPinConfirmation"
            max-width="md"
            @close="showPinConfirmation = false">
            <template #title>
                Pin Post
            </template>

            <template #content>
                Pin this post to the top of the feed? It will remove the current pinned post.
            </template>

            <template #footer>
                <div class="flex w-full flex-row items-center justify-end gap-4">
                    <Button
                        variant="white"
                        @click="showPinConfirmation = false">
                        Cancel
                    </Button>

                    <Button
                        variant="primary"
                        @click="pinPost">
                        Confirm
                    </Button>
                </div>
            </template>
        </Modal>

        <PostReportModal
            :post="post"
            :show="showReportConfirmation"
            @close="showReportConfirmation = false" />
    </div>
</template>
