<script setup lang="ts">
import ProfileLayout from '@/Layouts/ProfileLayout.vue'
import { type PropType } from 'vue'
import type { Tag } from '@/Types/Models/Tag'
import type { User } from '@/Types/Models/User'
import type { Post } from '@/Types/Models/Post'
import type { Game } from '@/Types/Models/Game'
import type { Article } from '@/Types/Models/Article'
import type { Platform } from '@/Types/Models/Platform'
import type { PaginatedItems, PaginationMeta } from '@/Types/PaginatedItems'
import { useProfileSectionsMap } from '@/Composables/useProfileSectionsMap.ts'

const props = defineProps({
    user: Object as PropType<User>,
    articleLimit: Number,
    hasArticles: Boolean,
    hasPosts: Boolean,
    profileSections: Array as PropType<{ title: string, description: string, key: string, enabled: boolean }[]>,
    articles: Object as PropType<PaginatedItems<Article>>,
    articlesPagination: Object as PropType<PaginationMeta>,
    taggedArticles: Object as PropType<{
        tag: { id: number, name: string },
        articles: PaginatedItems<Article>,
        pagination: PaginationMeta
    }[]>,
    tags: Array as PropType<Tag[]>,
    posts: Object as PropType<Post[]>,
    following: Array as PropType<User[]>,
    games: Array as PropType<Game[]>,
    consoles: Array as PropType<Platform[]>
})

const sections = props.profileSections.filter(section => section.enabled)

defineOptions({ layout: ProfileLayout })
</script>

<template>
    <div class="flex flex-col gap-8">
        <component
            :is="useProfileSectionsMap()[section.key]"
            v-for="section in sections"
            :key="section.key"
            :title="section.title"
            :description="section.description"
            v-bind="$props" />
    </div>
</template>
