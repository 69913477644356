<script setup>
import { computed, ref, watch } from 'vue'

const props = defineProps({
    name: {
        type: String,
        default: 'collapse'
    },
    dimension: {
        type: String,
        default: 'height',
        validator: (value) => {
            return ['height', 'width'].includes(value)
        }
    },
    duration: {
        type: Number,
        default: 200
    },
    easing: {
        type: String,
        default: 'ease-in-out'
    },
    fade: {
        type: Boolean,
        default: false
    }
})

const cachedStyles = ref(null)

const emit = defineEmits([
    'before-appear',
    'appear',
    'after-appear',
    'appear-cancelled',
    'before-enter',
    'enter',
    'after-enter',
    'enter-cancelled',
    'before-leave',
    'leave',
    'after-leave',
    'leave-cancelled'
])

const transition = computed(() => {
    const transitions = []
    if (cachedStyles.value) {
        Object.keys(cachedStyles.value).forEach((key) => {
            transitions.push(`${convertToCssProperty(key)} ${props.duration}ms ${props.easing}`)
        })
    }
    return transitions.join(', ')
})

const clearCachedDimensions = () => {
    cachedStyles.value = null
}

const detectAndCacheDimensions = (el) => {
    if (cachedStyles.value) return

    const visibility = el.style.visibility
    const display = el.style.display

    el.style.visibility = 'hidden'
    el.style.display = ''

    cachedStyles.value = detectRelevantDimensions(el)

    el.style.visibility = visibility
    el.style.display = display
}

const detectRelevantDimensions = (el) => {
    if (props.dimension === 'height') {
        return {
            height: el.offsetHeight + 'px',
            paddingTop: el.style.paddingTop || getCssValue(el, 'padding-top'),
            paddingBottom: el.style.paddingBottom || getCssValue(el, 'padding-bottom'),
            opacity: props.fade ? 0 : 1
        }
    }
    if (props.dimension === 'width') {
        return {
            width: el.offsetWidth + 'px',
            paddingLeft: el.style.paddingLeft || getCssValue(el, 'padding-left'),
            paddingRight: el.style.paddingRight || getCssValue(el, 'padding-right')
        }
    }
    return {}
}

const setTransition = (el) => {
    el.style.transition = transition.value
}

const unsetTransition = (el) => {
    el.style.transition = ''
}

const hideOverflow = (el) => {
    el.style.overflow = 'hidden'
}

const unsetOverflow = (el) => {
    el.style.overflow = ''
}

const setClosedDimensions = (el) => {
    Object.keys(cachedStyles.value).forEach((key) => {
        el.style[key] = '0'
    })
}

const setOpenedDimensions = (el) => {
    Object.keys(cachedStyles.value).forEach((key) => {
        el.style[key] = cachedStyles.value[key]
    })
}

const unsetDimensions = (el) => {
    Object.keys(cachedStyles.value).forEach((key) => {
        el.style[key] = ''
    })
}

const forceRepaint = (el) => {
    return getComputedStyle(el)[props.dimension]
}

const getCssValue = (el, style) => {
    return getComputedStyle(el, null).getPropertyValue(style)
}

const convertToCssProperty = (style) => {
    const upperChars = style.match(/([A-Z])/g)
    if (!upperChars) return style

    for (let i = 0; i < upperChars.length; i++) {
        style = style.replace(new RegExp(upperChars[i]), '-' + upperChars[i].toLowerCase())
    }

    if (style.slice(0, 1) === '-') {
        style = style.slice(1)
    }

    return style
}

const beforeAppear = (el) => {
    emit('before-appear', el)
}

const appear = (el) => {
    emit('appear', el)
}

const afterAppear = (el) => {
    emit('after-appear', el)
}

const appearCancelled = (el) => {
    emit('appear-cancelled', el)
}

const beforeEnter = (el) => {
    emit('before-enter', el)
}

const enter = (el, done) => {
    detectAndCacheDimensions(el)
    setClosedDimensions(el)
    hideOverflow(el)
    forceRepaint(el)
    setTransition(el)
    setOpenedDimensions(el)
    emit('enter', el, done)
    setTimeout(done, props.duration)
}

const afterEnter = (el) => {
    unsetOverflow(el)
    unsetTransition(el)
    unsetDimensions(el)
    clearCachedDimensions()
    emit('after-enter', el)
}

const enterCancelled = (el) => {
    emit('enter-cancelled', el)
}

const beforeLeave = (el) => {
    emit('before-leave', el)
}

const leave = (el, done) => {
    detectAndCacheDimensions(el)
    setOpenedDimensions(el)
    hideOverflow(el)
    forceRepaint(el)
    setTransition(el)
    setClosedDimensions(el)
    emit('leave', el, done)
    setTimeout(done, props.duration)
}

const afterLeave = (el) => {
    unsetOverflow(el)
    unsetTransition(el)
    unsetDimensions(el)
    clearCachedDimensions()
    emit('after-leave', el)
}

const leaveCancelled = (el) => {
    emit('leave-cancelled', el)
}

watch(() => props.dimension, () => {
    clearCachedDimensions()
})
</script>

<template>
    <transition
        :name="name"
        @appear="appear"
        @enter="enter"
        @leave="leave"
        @before-appear="beforeAppear"
        @after-appear="afterAppear"
        @appear-cancelled="appearCancelled"
        @before-enter="beforeEnter"
        @after-enter="afterEnter"
        @enter-cancelled="enterCancelled"
        @before-leave="beforeLeave"
        @after-leave="afterLeave"
        @leave-cancelled="leaveCancelled">
        <slot />
    </transition>
</template>
