<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
            x="0.75"
            y="0.75"
            width="22.5"
            height="22.5"
            rx="11.25"
            stroke="currentColor"
            stroke-width="1.5" />
        <path
            d="M8 16L12 12L16 8M9 9H9.01067V9.01067H9V9ZM9.5 9C9.5 9.13261 9.44732 9.25979 9.35355 9.35355C9.25979 9.44732 9.13261 9.5 9 9.5C8.86739 9.5 8.74021 9.44732 8.64645 9.35355C8.55268 9.25979 8.5 9.13261 8.5 9C8.5 8.86739 8.55268 8.74021 8.64645 8.64645C8.74021 8.55268 8.86739 8.5 9 8.5C9.13261 8.5 9.25979 8.55268 9.35355 8.64645C9.44732 8.74021 9.5 8.86739 9.5 9ZM15 15H15.0107V15.0107H15V15ZM15.5 15C15.5 15.1326 15.4473 15.2598 15.3536 15.3536C15.2598 15.4473 15.1326 15.5 15 15.5C14.8674 15.5 14.7402 15.4473 14.6464 15.3536C14.5527 15.2598 14.5 15.1326 14.5 15C14.5 14.8674 14.5527 14.7402 14.6464 14.6464C14.7402 14.5527 14.8674 14.5 15 14.5C15.1326 14.5 15.2598 14.5527 15.3536 14.6464C15.4473 14.7402 15.5 14.8674 15.5 15Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>
</template>
