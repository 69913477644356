<script setup lang="ts">
import Card from '@/Components/UI/Card.vue'
import Button from '@/Components/UI/Button.vue'
import PushPinImage from '~/app/decoration/push-pin.svg'
import UserAvatar from '@/Components/User/UserAvatar.vue'
import PostImages from '@/Components/Post/PostImages.vue'
import ModelMapReverse from '@/Configs/ModelMapReverse.ts'
import LikeButton from '@/Components/Global/LikeButton.vue'
import PostDropdown from '@/Components/Post/PostDropdown.vue'
import CommentList from '@/Components/Comment/CommentList.vue'
import VerifiedBadge from '@/Components/User/VerifiedBadge.vue'
import { Link } from '@inertiajs/vue3'
import { type PropType, ref } from 'vue'
import type { Post } from '@/Types/Models/Post'
import { usePlural } from '@/Composables/usePlural'
import { useRoute } from '@/Composables/useRoute.ts'
import { useDateFormat, useTimeAgo } from '@vueuse/core'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useIsAuthedUser } from '@/Composables/useIsAuthedUser'
import { ChatBubbleOvalLeftIcon } from '@heroicons/vue/24/outline'
import { useLinkifyString } from '@/Composables/useLinkifyString.ts'
import { useFormatUserTags } from '@/Composables/useFormatUserTags.ts'

const props = defineProps({
    post: Object as PropType<Post>,
    comments: Array as PropType<Comment[]>,
    showComments: {
        type: Boolean,
        default: false
    },
    actions: {
        type: Boolean,
        default: true
    },
    showPinned: {
        type: Boolean,
        default: true
    }
})

const emit = defineEmits(['reload', 'deleted'])

function reload () {
    emit('reload')
}

function handleDeletion () {
    emit('deleted')
}

const clicked = ref(false)
const createdDate = useDateFormat(props.post.created_at, 'YYYY-MM-DD hh:mma', { locales: usePageProps().app.locale })
const timeAgo = useTimeAgo(new Date(props.post.created_at))

function formatContent (content: string) {
    let formattedContent = useLinkifyString(content)
    formattedContent = useFormatUserTags(formattedContent, props.post.taggedUsers)

    return formattedContent
}
</script>

<template>
    <div>
        <Card
            :class="post.is_pinned && showPinned ? 'ring-2 ring-primary/75 ring-offset-2' : ''">
            <div class="relative flex flex-col">
                <div v-if="post.is_pinned && showPinned">
                    <img
                        v-tooltip="`Pinned post`"
                        :alt="`Pinned post`"
                        :src="PushPinImage"
                        class="absolute -top-1 right-8 z-20 w-6">
                </div>
                <div class="relative flex scroll-mt-20 items-start group single-post">
                    <component
                        :is="Link"
                        :href="useRoute('user.show', post.user)"
                        class="relative flex w-8 items-center avatar-container md:w-12">
                        <UserAvatar
                            :user="post.user"
                            class="w-full rounded-full ring-1 ring-slate-900/10"
                            size="small" />
                    </component>

                    <div class="ml-4 flex w-full flex-1 flex-col">
                        <div class="flex w-full items-center gap-2">
                            <div class="flex items-center gap-2">
                                <component
                                    :is="Link"
                                    :href="useRoute('user.show', post.user)"
                                    class="font-semibold">
                                    {{ post.user.name }}
                                </component>
                                <VerifiedBadge
                                    :user="post.user"
                                    class="w-5" />
                            </div>
                            <span class="text-sm text-slate-900/75 dark:text-white/75">&bull;</span>
                            <a
                                v-tooltip="`${createdDate}`"
                                :href="post.permalink"
                                class="mt-px text-xs text-slate-900/75 dark:text-white/75"
                                v-html="timeAgo" />

                            <div class="absolute -top-2 right-0">
                                <PostDropdown
                                    v-if="!post.is_deleted && actions"
                                    :post="post"
                                    @pinned="reload"
                                    @unpinned="reload"
                                    @deleted="handleDeletion" />
                            </div>
                        </div>
                        <div
                            :class="[{'line-clamp-4': !clicked}]"
                            class="my-1 break-all text-sm"
                            @click="clicked = true"
                            v-html="formatContent(post.content)" />
                        <PostImages
                            v-if="post.images"
                            class="my-4 w-full"
                            :post="post" />

                        <div class="mt-2 flex items-center gap-2">
                            <LikeButton
                                :can="true"
                                :count="post.likers_count"
                                :is-me="useIsAuthedUser(post.user)"
                                :liked="post.is_liked"
                                :show-text="true"
                                padding="px-2 py-1"
                                :url="useRoute('posts.like.toggle', {post: post})" />

                            <div class="flex">
                                <Button
                                    variant="white"
                                    :href="post.permalink + '#comments'"
                                    class="flex"
                                    padding="px-2 py-1">
                                    <div class="flex items-center gap-1.5">
                                        <ChatBubbleOvalLeftIcon class="h-5 text-gray-500" />
                                        <div
                                            class="text-xs min-w-4">
                                            {{ post.comments_count }} {{ usePlural('comment', post.comments_count) }}
                                        </div>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Card>

        <CommentList
            v-if="showComments"
            id="comments"
            class="mt-8 bg-background md:px-4"
            :form-data="{
                commentable_id: post.id,
                commentable_type: ModelMapReverse['Post']
            }"
            :show-title="true"
            title-size="text-xl"
            :count="post.comments_count"
            :commentable="post"
            :comments="comments"
            @created="reload"
            @deleted="reload" />
    </div>
</template>
