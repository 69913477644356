<script setup>
import Button from '@/Components/UI/Button.vue'
import TabLinks from '@/Components/UI/TabLinks.vue'
import Separator from '@/Components/UI/Separator.vue'
import UserList from '@/Components/User/UserList.vue'
import GameGrid from '@/Components/Game/GameGrid.vue'
import Pagination from '@/Components/UI/Pagination.vue'
import ArticleGrid from '@/Components/Article/ArticleGrid.vue'
import { onMounted, ref } from 'vue'
import { router } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { usePlural } from '@/Composables/usePlural.js'
import { MagnifyingGlassIcon, MicrophoneIcon } from '@heroicons/vue/24/outline'
import { MicrophoneIcon as MicrophoneIconSolid } from '@heroicons/vue/24/solid'

const props = defineProps({
    articles: Object,
    games: Object,
    users: Array,
    // comments: Array,
    discover: Array,
    q: String,
    type: String,
    order: String,
    direction: String
})

const form = ref({
    q: props.q
})

const options = ref([
    {
        key: 'all',
        title: 'All',
        link: useRoute('search.index', {
            q: form.value.q,
            type: 'all'
        }),
        count: count('all')
    },
    {
        key: 'users',
        title: 'Users',
        link: useRoute('search.index', {
            q: form.value.q,
            type: 'users'
        }),
        count: count('users')
    },
    {
        key: 'articles',
        title: 'Article',
        link: useRoute('search.index', {
            q: form.value.q,
            type: 'articles'
        }),
        count: count('articles')
    },
    {
        key: 'games',
        title: 'Games',
        link: useRoute('search.index', {
            q: form.value.q,
            type: 'games'
        }),
        count: count('games')
    }
])

function loadDiscover () {
    if (count('all') === 0) {
        router.reload({
            only: ['discover']
        })
    }
}

const searchInput = ref(null)

function submitSearch () {
    router.get(useRoute('search.index', {
        q: form.value.q,
        type: props.type
    }), {}, {
        onSuccess: () => loadDiscover()
    })
    // loadDiscover()
}

function blurInput () {
    searchInput.value.blur()
}

function count (type) {
    if (type === 'all') {
        return props.users?.length + props.articles?.meta?.total + props.games?.meta?.total
    }

    if (type === 'articles') {
        return props.articles?.meta?.total ?? 0
    } else if (type === 'games') {
        return props.games?.meta?.total ?? 0
    } else if (props[type]) {
        return props[type].length ?? 0
    }
}

const recognition = ref(null)
const talking = ref(false)

function toggleVoiceSearch () {
    if (recognition.value) {
        if (talking.value) {
            recognition.value.stop()
            talking.value = false
        } else {
            talking.value = true
            recognition.value.start()
        }
    }
}

const isSpeechEnabled = ref(false)
async function checkMicrophonePermission () {
    try {
        const permissionStatus = await navigator.permissions.query({ name: 'microphone' })
        isSpeechEnabled.value = permissionStatus.state === 'granted' || permissionStatus.state === 'prompt'

        permissionStatus.onchange = () => {
            isSpeechEnabled.value = permissionStatus.state === 'granted' || permissionStatus.state === 'prompt'
        }
    } catch (error) {
        console.error('Permission API error:', error)
    }
}

onMounted(() => {
    loadDiscover()

    if ('webkitSpeechRecognition' in window) {
        // eslint-disable-next-line new-cap,no-undef
        recognition.value = new webkitSpeechRecognition()
        recognition.value.continuous = false
        recognition.value.interimResults = false
        recognition.value.lang = 'en-US'

        recognition.value.onresult = (event) => {
            form.value.q = event.results[0][0].transcript
            talking.value = false
            submitSearch()
        }

        recognition.value.onerror = (event) => {
            console.error(event.error)
        }

        checkMicrophonePermission()
    } else {
        alert('Speech recognition not supported in this browser.')
    }
})
</script>

<template>
    <div>
        <div class="relative w-full pt-20 pb-16 bg-background dark:bg-slate-800 md:py-28">
            <div class="container">
                <div class="mx-auto w-full max-w-4xl">
                    <form
                        class="relative"
                        @submit.prevent="submitSearch">
                        <input
                            ref="searchInput"
                            v-model="form.q"
                            autofocus
                            class="w-full border-b bg-transparent py-2 pr-12 text-2xl border-secondary focus:border-primary focus:outline-0 focus:ring-0 dark:border-white"
                            placeholder="Search..."
                            type="text"
                            @keyup.esc="blurInput">
                        <div class="absolute top-2 right-2 flex items-center gap-2">
                            <button
                                v-if="isSpeechEnabled"
                                v-tooltip="'Start voice search'"
                                type="button"
                                :class="[talking ? 'text-red-400 animate-pulse' : 'text-secondary']"
                                @click="toggleVoiceSearch">
                                <component
                                    :is="talking ? MicrophoneIconSolid : MicrophoneIcon"
                                    class="w-7" />
                            </button>
                            <button
                                class="cursor-pointer"
                                type="submit">
                                <span class="sr-only">Submit search</span>
                                <MagnifyingGlassIcon class="w-8" />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div
            v-if="q"
            class="my-4 overflow-scroll md:container">
            <TabLinks
                class="mr-4"
                :border="false"
                :current="type"
                :tabs="options" />
        </div>

        <div
            v-if="!q"
            class="container mx-auto my-12 text-center">
            Search for articles, users, or other keywords
        </div>
        <div
            v-if="q"
            class="container mx-auto my-8">
            <div v-if="!count(type)">
                <article class="container mx-auto flex max-w-xl flex-col py-12 text-center">
                    <h1 class="text-2xl font-semibold font-heading">
                        No results
                    </h1>
                    <p>
                        Seems “{{ q }}” couldn't be found {{ type === 'all' ? 'anywhere' : 'in ' + type }}.
                    </p>
                </article>

                <div
                    v-if="discover && discover.length"
                    class="mx-auto max-w-4xl">
                    <Separator
                        colour="text-slate-900/75"
                        line-colour="bg-secondary/20">
                        Discover articles
                    </Separator>
                </div>

                <ArticleGrid
                    v-if="discover && discover.length"
                    :articles="discover"
                    :contain="false"
                />
            </div>
            <div class="flex flex-col gap-16">
                <UserList
                    v-if="['all', 'users'].includes(type) && users.length"
                    :title="users.length + ' ' + usePlural('user', users.length)"
                    :users="users" />
                <div class="flex flex-col">
                    <ArticleGrid
                        v-if="['all', 'articles'].includes(type) && articles.data.length"
                        :articles="articles.data"
                        :title="articles.meta.total + ' ' + usePlural('article', articles.meta.total)"
                        :contain="false"
                    />
                    <div
                        v-if="articles.meta.total > articles.data.length && type === 'all'"
                        class="flex">
                        <Button
                            variant="primary"
                            :href="options.find(option => option.key === 'articles').link">
                            See more
                        </Button>
                    </div>
                    <Pagination
                        v-if="articles.meta.total > articles.data.length && type === 'articles'"
                        :links="articles.meta.links" />
                </div>
                <div class="flex flex-col">
                    <GameGrid
                        v-if="['all', 'games'].includes(type) && games.data.length"
                        :allow-ad="false"
                        game-width="w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5"
                        :title="games.meta.total + ' ' + usePlural('game', games.meta.total)"
                        :games="games.data" />
                    <div
                        v-if="games.meta.total > games.data.length && type === 'all'"
                        class="flex">
                        <Button
                            variant="primary"
                            :href="options.find(option => option.key === 'games').link">
                            See more
                        </Button>
                    </div>
                    <Pagination
                        v-if="games.meta.total > games.data.length && type === 'games'"
                        :links="games.meta.links" />
                </div>
            </div>
        </div>
    </div>
</template>
