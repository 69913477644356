<script setup>
import Button from '@/Components/UI/Button.vue'
import useEmitter from '@/Composables/useEmitter.js'
import { ref } from 'vue'
import { usePost } from '@/Composables/usePost'
import { useToast } from '@/Composables/useToast.ts'
import { HeartIcon } from '@heroicons/vue/24/outline'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { usePlural } from '../../Composables/usePlural.js'
import { HeartIcon as FullHeartIcon } from '@heroicons/vue/24/solid'
import { useAnalyticsEvent } from '@/Composables/useAnalyticsEvent.js'

const props = defineProps({
    url: String,
    count: Number,
    liked: Boolean,
    isMe: Boolean,
    showText: {
        type: Boolean,
        default: false
    },
    likeTooltip: {
        type: [String, null],
        default: null
    },
    padding: {
        type: [String, null],
        default: 'px-2 py-1.5'
    },
    unlikeTooltip: {
        type: [String, null],
        default: null
    },
    can: {
        type: Boolean,
        default: false
    },
    light: {
        type: Boolean,
        default: false
    },
    showLoginModal: {
        type: Boolean,
        default: true
    },
    showCount: {
        type: Boolean,
        default: true
    }
})

const displayCount = ref(props.count)
const displayLiked = ref(props.liked)
const displayPulse = ref(false)

function handleClick () {
    if (props.showLoginModal && !useIsAuthed()) {
        useEmitter.emit('openAuthModal', {
            loginTitle: 'Login to like things',
            defaultForm: 'login'
        })
    }

    if (!props.isMe && props.can) {
        displayLiked.value = !displayLiked.value

        if (!displayLiked.value) {
            displayCount.value = displayCount.value - 1
        } else {
            displayCount.value = displayCount.value + 1
            displayPulse.value = true
        }

        usePost({
            url: props.url,
            onUnauthorized: () => useToast('Unauthorized action', 'fail'),
            onSuccess: response => {
                displayCount.value = response.count
                displayLiked.value = response.liked

                useAnalyticsEvent(response.liked ? 'Like item' : 'Unlike item')
            }
        })
    }
}
</script>
<template>
    <div>
        <div
            class="flex items-center">
            <Button
                v-tooltip="displayLiked ? unlikeTooltip : likeTooltip"
                variant="white"
                :disabled="isMe"
                :padding="padding"
                @click="handleClick">
                <div class="flex items-center gap-1.5">
                    <span v-if="!displayLiked && !isMe">
                        <HeartIcon class="h-5 w-5 text-gray-500" />
                    </span>
                    <span
                        v-else
                        class="relative block">
                        <FullHeartIcon
                            v-if="displayPulse && !isMe"
                            class="absolute top-0 left-0 h-5 w-5 text-red-500 animate-ping-once" />
                        <FullHeartIcon
                            :class="isMe ? 'opacity-50 text-gray-500' : 'text-red-500'"
                            class="h-5 w-5" />
                    </span>
                    <span
                        v-if="showCount"
                        :class="showText ? 'text-xs' : ''"
                        class="min-w-4">
                        {{ displayCount }} {{ showText ? usePlural('Like', displayCount) : '' }}
                    </span>
                </div>
            </Button>
        </div>
    </div>
</template>
