<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4 13.5H20.5M4 17.25H20.5M5.875 6H18.625C19.1223 6 19.5992 6.19754 19.9508 6.54917C20.3025 6.90081 20.5 7.37772 20.5 7.875C20.5 8.37228 20.3025 8.84919 19.9508 9.20083C19.5992 9.55246 19.1223 9.75 18.625 9.75H5.875C5.37772 9.75 4.90081 9.55246 4.54917 9.20083C4.19754 8.84919 4 8.37228 4 7.875C4 7.37772 4.19754 6.90081 4.54917 6.54917C4.90081 6.19754 5.37772 6 5.875 6Z"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>
</template>
