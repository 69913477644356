<script setup>
import Modal from '@/Components/UI/Modal.vue'
import Button from '@/Components/UI/Button.vue'
import useEmitter from '@/Composables/useEmitter'
import { onMounted, ref } from 'vue'
import { router } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'

const article = ref(null)

const confirmDelete = ref(false)
const confirmWithdraw = ref(false)
const confirmUnpublish = ref(false)
const confirmPermanentDelete = ref(false)

function withdrawSubmission () {
    router.patch(useRoute('articles.withdraw', article.value), {}, {
        preserveScroll: true
    })
    confirmWithdraw.value = false
}

function unpublishArticle () {
    router.patch(useRoute('articles.unpublish', article.value), {}, {
        preserveScroll: true
    })
    confirmUnpublish.value = false
}

function openPermanentlyDeleteModal () {
    confirmPermanentDelete.value = true
    setTimeout(() => {
        focusButton('permanentlyDeleteButton')
    }, 100)
}

function closePermanentlyDeleteModal () {
    blurButton('permanentlyDeleteButton')
    confirmPermanentDelete.value = false
}

function blurButton (classname) {
    const button = document.getElementsByClassName(classname)[0]
    button.blur()
}

function focusButton (classname) {
    const button = document.getElementsByClassName(classname)[0]
    button.focus()
}

function permanentlyDeleteArticle () {
    router.delete(useRoute('articles.delete.force', article.value), {
        preserveScroll: true
    })
    confirmPermanentDelete.value = false
}

function deleteArticle () {
    router.delete(useRoute('articles.delete', article.value), {
        preserveScroll: true
    })
    confirmDelete.value = false
}

onMounted(() => {
    useEmitter.on('article:delete', function (selectedArticle) {
        article.value = selectedArticle
        confirmDelete.value = true
    })

    useEmitter.on('article:delete:permanent', function (selectedArticle) {
        article.value = selectedArticle
        openPermanentlyDeleteModal()
    })

    useEmitter.on('article:unpublish', function (selectedArticle) {
        article.value = selectedArticle
        confirmUnpublish.value = true
    })

    useEmitter.on('article:withdraw', function (selectedArticle) {
        article.value = selectedArticle
        confirmWithdraw.value = true
    })
})
</script>

<template>
    <div>
        <Modal
            :show="confirmDelete"
            max-width="md"
            @close="confirmDelete = false">
            <template #title>
                Delete Article
            </template>

            <template #content>
                Are you sure? <br><strong>{{ article.title ?? '[Untitled]' }}</strong> will be unpublished and moved to
                your trash.
            </template>

            <template #footer>
                <div class="flex w-full flex-row items-center justify-end gap-4">
                    <Button
                        variant="white"
                        @click="confirmDelete = false">
                        Cancel
                    </Button>

                    <Button
                        class="confirmDeleteButton"
                        variant="danger"
                        @click="deleteArticle">
                        Delete
                    </Button>
                </div>
            </template>
        </Modal>

        <Modal
            :show="confirmPermanentDelete"
            max-width="md"
            @close="closePermanentlyDeleteModal">
            <template #title>
                Permanently Delete Article
            </template>

            <template #content>
                Are you sure? <br><strong>{{ article.title ?? '[Untitled]' }}</strong> will be permanently deleted, this
                is not reversable.
            </template>

            <template #footer>
                <div class="flex w-full flex-row items-center justify-end gap-4">
                    <Button
                        variant="white"
                        @click="closePermanentlyDeleteModal">
                        Cancel
                    </Button>

                    <Button
                        class="permanentlyDeleteButton"
                        variant="danger"
                        @click="permanentlyDeleteArticle">
                        Delete
                    </Button>
                </div>
            </template>
        </Modal>

        <Modal
            :show="confirmUnpublish"
            max-width="md"
            @close="confirmUnpublish = false">
            <template #title>
                Unpublish Article?
            </template>

            <template #content>
                Are you sure? <br>
                <strong>{{ article.title ?? '[Untitled]' }}</strong> will be moved to your
                drafts.
            </template>

            <template #footer>
                <div class="flex w-full flex-row items-center justify-end gap-4">
                    <Button
                        variant="white"
                        @click="confirmUnpublish = false">
                        Cancel
                    </Button>

                    <Button
                        class="confirmUnpublishButton"
                        variant="danger"
                        @click="unpublishArticle">
                        Unpublish
                    </Button>
                </div>
            </template>
        </Modal>

        <Modal
            :show="confirmWithdraw"
            max-width="md"
            @close="confirmWithdraw = false">
            <template #title>
                Withdraw submission?
            </template>

            <template #content>
                Are you sure? <br><strong>{{ article.title ?? '[Untitled]' }}</strong> will be moved to your drafts
            </template>

            <template #footer>
                <div class="flex w-full flex-row items-center justify-end gap-4">
                    <Button
                        variant="white"
                        @click="confirmWithdraw = false">
                        Cancel
                    </Button>

                    <Button
                        class="confirmWithdrawButton"
                        variant="danger"
                        @click="withdrawSubmission">
                        Withdraw
                    </Button>
                </div>
            </template>
        </Modal>
    </div>
</template>
