<script setup lang="ts">
import Card from '@/Components/UI/Card.vue'
import InputLabel from '@/Components/Input/InputLabel.vue'
import InputRadio from '@/Components/Input/InputRadio.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import InputToggle from '@/Components/Input/InputToggle.vue'
import InputPassword from '@/Components/Input/InputPassword.vue'
import InputDatePicker from '@/Components/Input/InputDatePicker.vue'
import AccordionTransition from '@/Components/Transition/AccordionTransition.vue'
import { type PropType, ref, watch } from 'vue'
import { ArticleAccess } from '@/Enums/ArticleAccess.ts'
import { ChevronUpIcon } from '@heroicons/vue/24/outline'
import { usePageProps } from '@/Composables/usePageProps.ts'
import type { ArticleMetadata } from '@/Types/Models/Article'
import { useDateFormat, useNow, useTimeAgo } from '@vueuse/core'
import { useArticleAccessInfo } from '@/Composables/useArticleAccessInfo.ts'

interface Form {
    metadata: ArticleMetadata,
    publish_at: string | null
    errors: Record<string, string>
}

const form = defineModel('form', {
    type: Object as PropType<Form>,
    required: true
})

const showOptions = ref(true)
const schedulePublish = ref(form.value.publish_at !== null)
const articleAccessOptions = [
    {
        value: ArticleAccess.SUBSCRIBERS,
        label: useArticleAccessInfo(ArticleAccess.SUBSCRIBERS).title
    },
    {
        value: ArticleAccess.LINKED,
        label: useArticleAccessInfo(ArticleAccess.LINKED).title
    },
    {
        value: ArticleAccess.PASSWORD,
        label: useArticleAccessInfo(ArticleAccess.PASSWORD).title
    }
]

watch(schedulePublish, () => {
    form.value.publish_at = schedulePublish.value ? useDateFormat(useNow(), 'YYYY-MM-DD hh:mma', { locales: usePageProps().app.locale }).value : null
})
</script>

<template>
    <Card>
        <SectionTitle
            font-size="text-xl"
            as="button"
            class="flex w-full items-center justify-between"
            @click="showOptions = !showOptions">
            Options
            <ChevronUpIcon
                class="w-5 transition-all"
                :class="showOptions ? '' : 'rotate-180'" />
        </SectionTitle>
        <AccordionTransition>
            <div
                v-if="showOptions"
                class="flex flex-col gap-4 pt-4">
                <InputToggle
                    v-model="form.metadata.allow_comments"
                    description="Allow users to comment on this article."
                    label="Comments" />
                <InputToggle
                    v-model="form.metadata.has_spoiler"
                    description="Display spoiler warning before anyone reads."
                    label="Spoiler" />
                <InputToggle
                    v-model="form.metadata.public"
                    description="Allow anyone to view this article."
                    label="Public" />

                <div v-if="!form.metadata.public">
                    <InputLabel>Who can view this article</InputLabel>
                    <InputRadio
                        v-model="form.metadata.accessible_by"
                        class="mt-2"
                        spacing="gap-2"
                        :options="articleAccessOptions"
                        label="Rating (optional)" />

                    <InputPassword
                        v-if="form.metadata.accessible_by === ArticleAccess.PASSWORD"
                        v-model="form.metadata.password"
                        autocomplete="new-password"
                        :error="form.errors['metadata.password']"
                        class="mt-2"
                        label="Password" />
                </div>

                <div class="flex w-full flex-col gap-2">
                    <div>
                        <InputToggle
                            v-model="schedulePublish"
                            description="Publish this article at a later date."
                            label="Schedule" />
                    </div>
                    <div class="mt-2">
                        <div>
                            <InputDatePicker
                                v-if="schedulePublish"
                                v-model="form.publish_at"
                                :future-only="true"
                                class="w-full"
                                placeholder="Publish at..." />
                        </div>
                        <div
                            v-if="schedulePublish"
                            class="mt-1 flex justify-end text-xs text-gray-900/50">
                            {{ useTimeAgo(form.publish_at).value }}
                        </div>
                    </div>
                </div>
            </div>
        </AccordionTransition>
    </Card>
</template>
