<script setup>
import 'vue-datepicker-next/index.css'
import Button from '@/Components/UI/Button.vue'
import Dropdown from '@/Components/UI/Dropdown.vue'
import InputDatePicker from '@/Components/Input/InputDatePicker.vue'
import { MenuItem } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'

const selectedRange = defineModel('selectedRange', {
    type: String,
    default: '7'
})

const range = defineModel('range', {
    type: Array,
    default: [null, null]
})

const options = [
    {
        value: 'today',
        label: 'Today'
    },
    {
        value: '7',
        label: 'Last 7 days'
    },
    {
        value: '28',
        label: 'Last 28 days'
    },
    {
        value: '60',
        label: 'Last 60 days'
    },
    {
        value: 'all',
        label: 'All time'
    },
    {
        value: 'custom',
        label: 'Custom range'
    }
]
</script>

<template>
    <div class="flex items-center gap-4">
        <div
            v-if="selectedRange === 'custom'"
            class="w-44">
            <InputDatePicker
                v-model="range"
                :max-date="new Date()"
                :future-only="false"
                :range="true"
                :time="false"
                placeholder="Select start date" />
        </div>

        <Dropdown>
            <template #toggle>
                <Button>
                    {{ options.find(option => option.value === selectedRange)?.label }}
                    <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" />
                </Button>
            </template>
            <template #items>
                <div class="flex items-center">
                    <ul class="w-full">
                        <li
                            v-for="option in options"
                            :key="option.value">
                            <MenuItem
                                v-slot="{ active }"
                                as="button"
                                class="w-full text-left"
                                @click="selectedRange = option.value">
                                <div
                                    :class="{
                                        'text-white bg-primary': selectedRange === option.value,
                                        'bg-background': active && selectedRange !== option.value,
                                    }"
                                    class="dropdown-item">
                                    {{ option.label }}
                                </div>
                            </MenuItem>
                        </li>
                    </ul>
                </div>
            </template>
        </Dropdown>
    </div>
</template>
