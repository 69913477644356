<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
    unicode: String,
    useImage: {
        type: Boolean,
        default: false
    }
})

const emojiCharacter = computed(() => {
    return String.fromCodePoint(parseInt(props.unicode, 16))
})

const imageUrl = computed(() => {
    return `https://cdn.jsdelivr.net/npm/emoji-datasource-apple@6.0.1/img/apple/64/${props.unicode}.png`
})
</script>

<template>
    <div>
        <p v-if="!useImage">
            {{ emojiCharacter }}
        </p>
        <img
            v-else
            width="64"
            height="64"
            :src="imageUrl"
            :alt="emojiCharacter">
    </div>
</template>
