<script setup lang="ts">
import Logo from '@/Components/Logo'
import BaseLayout from '@/Layouts/BaseLayout.vue'
import DashboardSidebar from '@/Components/Dashboard/DashboardSidebar.vue'
import { Link } from '@inertiajs/vue3'
import { onMounted, onUnmounted, ref } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import { Bars3BottomLeftIcon, XMarkIcon } from '@heroicons/vue/24/outline'

const sidebarOpen = ref<boolean>(false)

function openSidebar () {
    sidebarOpen.value = true
    document.body.style.overflow = 'hidden'
}

function closeSidebar () {
    if (sidebarOpen.value) {
        sidebarOpen.value = false
        document.body.style.overflow = ''
    }
}

function toggleSidebar () {
    sidebarOpen.value === true ? closeSidebar() : openSidebar()
}

onMounted(() => {
    document.body.classList.add('bg-slate-950')
})

onUnmounted(() => {
    document.body.classList.remove('bg-slate-950')
})
</script>

<template>
    <BaseLayout
        :footer="false"
        :navbar="false">
        <div class="relative w-full bg-slate-950">
            <div class="flex items-start">
                <div
                    :class="sidebarOpen ? 'translate-x-0' : '-translate-x-full'"
                    class="fixed z-50 w-10/12 lg:translate-x-0 top-12 h-[calc(100dvh-3rem)] lg:h-dvh overflow-auto transition-transform lg:top-0 left-0 lg:z-50 lg:flex lg:w-64 lg:flex-col">
                    <DashboardSidebar @close="closeSidebar" />
                </div>
            </div>
            <div class="lg:pl-64 min-h-[calc(100dvh-1rem)]">
                <div class="relative z-50 flex h-12 w-full items-center justify-between pr-5 lg:hidden lg:h-14 lg:justify-end">
                    <div class="flex items-center gap-1 lg:hidden">
                        <button
                            class="flex w-12 items-center justify-center text-white"
                            @click="toggleSidebar">
                            <component
                                :is="sidebarOpen ? XMarkIcon : Bars3BottomLeftIcon"
                                class="w-6" />
                        </button>
                        <Link :href="useRoute('home')">
                            <Logo.FullWhite class="w-44 sm:w-40 lg:w-48" />
                        </Link>
                    </div>
                </div>
                <div
                    class="w-full dashboard-content min-h-[calc(100dvh-1rem)] bg-background dark:bg-slate-900 lg:my-2 transition-transform overflow-auto px-0 py-8 lg:p-8 lg:pb-0 rounded-t-xl lg:rounded-tr-none lg:rounded-l-xl">
                    <button
                        :class="sidebarOpen ? 'pointer-events-auto bg-black/50' : 'pointer-events-none bg-transparent'"
                        class="fixed top-12 right-0 z-40 h-full w-full transition-all lg:hidden"
                        @click="closeSidebar" />
                    <div class="container h-full">
                        <slot />
                    </div>
                </div>
            </div>
        </div>
    </BaseLayout>
</template>
