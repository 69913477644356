<script setup lang="ts">
import * as UserPermission from '@/Enums/UserPermission'
import Button from '@/Components/UI/Button.vue'
import useEmitter from '@/Composables/useEmitter'
import { useToast } from '@/Composables/useToast.ts'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Article } from '@/Types/Models/Article'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { usePermissions } from '@/Composables/usePermissions'
import { useMakeRequest } from '@/Composables/useMakeRequest.js'
import { useAnalyticsEvent } from '@/Composables/useAnalyticsEvent.js'
import { getCurrentInstance, onMounted, type PropType, ref } from 'vue'

const props = defineProps({
    article: Object as PropType<Article>,
    bookmarked: Boolean,
    showLoginModal: {
        type: Boolean,
        default: true
    }
})

const emit = defineEmits(['bookmarked', 'unbookmarked'])

const animation = ref(false)
const saved = ref(props.bookmarked)
const inverted = ref(props.bookmarked)

const instance = getCurrentInstance()
const uid = ref(instance.uid)

function save () {
    animation.value = true
    saved.value = true
    useMakeRequest(useRoute('api.article.bookmark.add', props.article), 'POST', {})
        .then(results => {
            if (results.success === true) {
                emit('bookmarked')
                useToast('Saved to bookmarks')
                useAnalyticsEvent('Bookmark article')

                useEmitter.emit('bookmarked', {
                    article: props.article.uuid,
                    component: uid.value
                })
            }
        })

    // router.post(useRoute('articles.bookmark.add', props.article), {}, {
    //     preserveScroll: true,
    //     preserveState: true,
    //     onSuccess: () => useEmitter.emit('bookmarked')
    // })

    setTimeout(() => {
        animation.value = false
        setTimeout(() => {
            inverted.value = true
        }, 100)
    }, 750)
}

function remove () {
    animation.value = true
    saved.value = false

    useMakeRequest(useRoute('api.article.bookmark.remove', props.article), 'DELETE', {})
        .then(results => {
            if (results.success === true) {
                emit('unbookmarked')
                useToast('Removed from bookmarks')
                useAnalyticsEvent('Unbookmark article')

                useEmitter.emit('unbookmarked', {
                    article: props.article.uuid,
                    component: uid.value
                })
            }
        })

    setTimeout(() => {
        animation.value = false
        setTimeout(() => {
            inverted.value = false
        }, 100)
    }, 750)
}

function toggle () {
    if (useIsAuthed() && usePermissions(UserPermission.BOOKMARK_ARTICLES)) {
        saved.value === true ? remove() : save()
    } else if (props.showLoginModal && !useIsAuthed()) {
        useEmitter.emit('openAuthModal', {
            loginTitle: 'Login to bookmark articles',
            defaultForm: 'login'
        })
    }
}

onMounted(() => {
    useEmitter.on('bookmarked', function (event: { article: Article['uuid'], component: number}) {
        if (event.article === props.article.uuid && event.component !== uid.value) {
            saved.value = !saved.value
        }
    })

    useEmitter.on('unbookmarked', function (event: { article: Article['uuid'], component: number}) {
        if (event.article === props.article.uuid && event.component !== uid.value) {
            saved.value = !saved.value
        }
    })
})
</script>

<template>
    <div>
        <Button
            v-tooltip="saved ? 'Unbookmark article' : 'Bookmark article'"
            variant="white"
            padding="px-1.5 py-1"
            :disabled="animation"
            @click="toggle">
            <div
                :class="{ animation, inverted }"
                class="relative scale-[0.65] bookmark-button group disabled:cursor-pointer">
                <span
                    class="pointer-events-none absolute top-1/2 block h-14 w-14 -translate-x-1/2 -translate-y-1/2 bursts left-[42%]">
                    <span
                        v-for="deg in [0, 45, 90, 135, 180, 225, 270, 315]"
                        :key="deg"
                        :style="`transform:rotate(${deg}deg);`"
                        class="pointer-events-none absolute h-full bg-transparent w-[3px]" />
                </span>
                <span
                    :class="{ saved }"
                    class="relative block h-6 w-5 border-x-0 border-b-0 bookmark clip-path-polygon rounded-t-[3px] bg-primary">
                    <span
                        class="absolute block w-4/5 bg-white inner-background clip-path-polygon left-[10%] top-[7.5%] h-[77.5%] rounded-t-[2px]" />
                </span>
            </div>
        </Button>
    </div>
</template>
