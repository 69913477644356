<script setup lang="ts">
import RatingType from '@/Enums/RatingType'
import type Thumb from '@/Types/Ratings/Thumb'
import ThumbIconMap from '@/Configs/ThumbIconMap.ts'
import BaseRatingForm from '@/Components/Ratings/BaseRatingForm.vue'
import type RatingFormComponent from '@/Types/Ratings/RatingFormComponent'
import { useForm } from '@inertiajs/vue3'
import type { RatingForm } from '@/Types/Ratings/RatingForm'

const props = defineProps<RatingFormComponent>()

const options: Thumb[] = ['up', 'neutral', 'down']

interface ThumbValue {
    id: number | string;
    value: Thumb;
    title: string;
}

const form = useForm<RatingForm<ThumbValue>>({
    rateable_type: props.rateableType,
    rateable_id: props.rateable.id,
    type: RatingType.THUMB as RatingType,
    title: '',
    description: '',
    meta: {
        values: [
            { id: 0, value: options[1], title: '' }
        ]
    }
})

function updateElement (element: ThumbValue, value: Thumb) {
    element.value = value
}
</script>

<template>
    <BaseRatingForm
        v-model:form="form"

        :default-value="{title: '', value: options[0]}"
    >
        <template #element="{ element }">
            <div class="mx-auto px-4 py-4 max-w-64 md:py-0">
                <ul class="flex items-center justify-between">
                    <li
                        v-for="option in options"
                        :key="option">
                        <button
                            class="mx-auto flex aspect-square items-center justify-center rounded-full p-2 transition-all"
                            :class="[
                                {
                                    'opacity-50 grayscale hover:opacity-75 hover:grayscale-0': element.value !== option,
                                    'scale-110': element.value === option
                                },
                                ThumbIconMap[option].classes.text,
                                ThumbIconMap[option].classes.bg,
                            ]"
                            @click="updateElement(element, option)">
                            <component
                                :is="ThumbIconMap[option].component"
                                :class="[
                                    ThumbIconMap[option].classes.icon,
                                    form.meta?.values?.length >= 4 ? 'w-6' : 'w-8'
                                ]" />
                        </button>
                    </li>
                </ul>
            </div>
        </template>
        <template #actions>
            <slot name="actions" />
        </template>
    </BaseRatingForm>
</template>
