<script setup lang="ts">
import type Grade from '@/Types/Ratings/Grade'
import RatingType from '@/Enums/RatingType.ts'
import Dropdown from '@/Components/UI/Dropdown.vue'
import GradeColourMap from '@/Configs/GradeColourMap.ts'
import BaseRatingForm from '@/Components/Ratings/BaseRatingForm.vue'
import type RatingFormComponent from '@/Types/Ratings/RatingFormComponent'
import { useForm } from '@inertiajs/vue3'
import { ref, useId, useTemplateRef } from 'vue'
import { MenuItem, MenuItems } from '@headlessui/vue'
import type { RatingForm } from '@/Types/Ratings/RatingForm.ts'

const props = defineProps<RatingFormComponent>()

const grades: Grade[] = ['S', 'A-', 'A', 'A+', 'B-', 'B', 'B+', 'C-', 'C', 'C+', 'D-', 'D', 'D+', 'F-', 'F', 'F+']
const options: Array<{ label: string, value: string }> = grades.map(grade => ({ label: grade, value: grade }))

interface GradeValue {
    id: number | string;
    value: string;
    title: string;
}

const form = useForm<RatingForm<GradeValue>>({
    rateable_type: props.rateableType,
    rateable_id: props.rateable.id,
    type: RatingType.GRADE,
    title: '',
    description: '',
    meta: {
        values: [
            { id: 0, value: grades[0], title: '' }
        ]
    }
})

function updateElement (element: GradeValue, newGrade: { label: string, value: string }) {
    element.value = newGrade.value
    // dropdown.value.close()
}

const id = useId()

// const dropdown = useTemplateRef('dropdown-' + id)
</script>

<template>
    <BaseRatingForm
        v-model:form="form"
        :default-value="{title: '', value: grades[0]}">
        <template #element="{ element }">
            <div class="mx-auto -mb-2 w-24 max-w-64">
                <div class="z-50">
                    <Dropdown
                        :ref="'dropdown-' + id"
                        toggle-classes="mx-auto rounded-md min-w-[45px] text-center"
                        class="relative z-20"
                        max-height="max-h-84">
                        <template #toggle>
                            <div
                                class="mx-auto flex flex-col justify-center gap-2">
                                <div
                                    class="text-7xl line-height-1 font-heading"
                                    :class="GradeColourMap[element.value].text">
                                    {{ element.value }}
                                </div>
                            </div>
                        </template>
                        <template #items>
                            <div>
                                <MenuItems
                                    as="ul"
                                    class="flex flex-wrap">
                                    <li
                                        v-for="option in options"
                                        :key="option.value"
                                        class="p-1"
                                        :class=" {
                                            'w-full': option.value === 'S',
                                            'w-1/3': option.value !== 'S'
                                        }"
                                    >
                                        <MenuItem
                                            v-slot="{ active }"
                                            as="button"
                                            class="w-full"
                                            @click="updateElement(element, option)">
                                            <div
                                                class="rounded px-4 py-2 text-center text-xl text-white transition-all font-heading hover:scale-105 hover:opacity-90"
                                                :class="[
                                                    GradeColourMap[option.value].bg,
                                                    active ? 'scale-105 opacity-90' : ''
                                                ]">
                                                {{ option.label }}
                                            </div>
                                        </MenuItem>
                                    </li>
                                </MenuItems>
                            </div>
                        </template>
                    </Dropdown>
                </div>
            </div>
        </template>
        <template #actions>
            <slot name="actions" />
        </template>
    </BaseRatingForm>
</template>
