<script setup>
import draggable from 'vuedraggable'
import { onMounted, ref, watch } from 'vue'
import { XMarkIcon, PhotoIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
    images: {
        type: Array,
        default: () => []
    },
    limit: {
        type: Number,
        default: 1
    }
})

const fileInput = ref(null)
const localImages = ref([])

const triggerFileInput = () => {
    fileInput.value.click()
}

const handleFiles = (event) => {
    const files = event.target.files
    const currentImageCount = localImages.value.length
    const allowedFilesCount = Math.min(files.length, props.limit - currentImageCount)

    for (let i = 0; i < allowedFilesCount; i++) {
        const file = files[i]
        if (!file.type.startsWith('image/') || file.type === 'image/svg+xml') {
            continue
        }

        const reader = new FileReader()
        reader.onload = (e) => {
            localImages.value.push({
                id: localImages.value.length, // Ensure unique ID
                file,
                url: e.target.result
            })
        }
        reader.readAsDataURL(file)
    }
}

const removeImage = (id) => {
    const index = localImages.value.findIndex(image => image.id === id)
    localImages.value.splice(index, 1)
}

const emit = defineEmits(['update:modelValue'])

watch(localImages, (newImages) => {
    emit('update:modelValue', newImages)
})

onMounted(() => {
    localImages.value = [...props.images]
})

defineOptions({
    inheritAttrs: false
})
</script>

<template>
    <div class="flex w-full flex-col">
        <input
            ref="fileInput"
            type="file"
            multiple
            accept="image/*"
            style="display:none"
            @change="handleFiles">

        <div class="flex">
            <button
                :disabled="localImages.length >= limit"
                variant="primary-light"
                type="button"
                class="flex items-center gap-2 text-xs text-slate-900/50 hover:text-slate-900 disabled:cursor-not-allowed disabled:hover:text-slate-900/50"
                @click="triggerFileInput">
                <PhotoIcon class="w-5" />
                ({{ localImages.length + '/' + limit }})
            </button>
        </div>

        <div class="mb-2 flex w-full items-center gap-2">
            <draggable
                v-model="localImages"
                group="tags"
                class="flex items-center -mx-1 shrink-0 w-[calc(100%+0.5rem)]"
                ghost-class="opacity-50"
                item-key="id">
                <template #item="{element}">
                    <div class="relative aspect-square w-2/12 shrink-0 rounded-lg p-1">
                        <div class="h-full w-full overflow-hidden rounded-lg border border-primary bg-primary/10">
                            <img
                                :src="element.url"
                                alt="Image Preview"
                                class="h-full w-full object-cover">
                        </div>
                        <button
                            v-tooltip="`Remove image`"
                            type="button"
                            class="absolute top-0 right-0 flex aspect-square w-5 items-center justify-center rounded-full text-white mr-1.5 mt-1.5 bg-primary"
                            @click="removeImage(element.id)">
                            <XMarkIcon class="w-4" />
                        </button>
                    </div>
                </template>
            </draggable>
        </div>
    </div>
</template>

<style scoped>
.image-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-list {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
}

.image-item {
    position: relative;
    margin: 10px;
}

.image-preview {
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
}

.remove-image {
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(255, 0, 0, 0.7);
    color: white;
    padding: 5px;
    cursor: pointer;
}
</style>
