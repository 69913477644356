<script setup lang="ts">
import Pagination from '@/Components/UI/Pagination.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import AdContainer from '@/Components/Global/AdContainer.vue'
import ArticleCard from '@/Components/Article/ArticleCard.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import CreateArticleLink from '@/Components/Article/CreateArticleLink.vue'
import ArticleSkeletonGrid from '@/Components/Article/ArticleSkeletonGrid.vue'
import ArticleCardHorizontal from '@/Components/Article/ArticleCardHorizontal.vue'
// @ts-ignore
import { debounce } from 'lodash'
import { Link } from '@inertiajs/vue3'
import type { Article } from '@/Types/Models/Article'
import type { PaginationMeta } from '@/Types/PaginatedItems'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { ChevronRightIcon, ArrowRightIcon } from '@heroicons/vue/20/solid'
import { computed, onMounted, onUnmounted, type PropType, ref, useSlots } from 'vue'

const props = defineProps({
    url: String,
    title: String | Number,
    description: String,
    articles: Object as PropType<Article[]>,
    icon: Object,
    pagination: Object as PropType<PaginationMeta>,
    titleFontSize: {
        type: [String, null],
        default: null
    },
    contain: {
        type: Boolean,
        default: true
    },
    buttonText: {
        type: String,
        default: 'See more'
    },
    skeletonCount: {
        type: Number,
        default: 4
    },
    buttonLimit: {
        type: Number,
        default: 2
    },
    allowAd: {
        type: Boolean,
        default: false
    },
    sliderOnDesktop: {
        type: Boolean,
        default: false
    }, // Warning
    horizontal: {
        type: Boolean,
        default: false
    },
    sliderOnMobile: {
        type: Boolean,
        default: false
    },
    includeWritingCta: {
        type: Boolean,
        default: false
    },
    writingCtaText: {
        type: String,
        default: 'Write your own article'
    },
    articleWidth: {
        type: String,
        default: 'md:w-1/2 lg:w-1/3 xl:w-1/4'
    }
})

const allArticles = computed(() => props.articles ? props.articles : [])

const sliderPadding = ref(0)
const gridSpacer = ref(null)

const displaySliderOnMobile = computed(() => {
    return props.sliderOnMobile && allArticles.value.length > 1
})

const containerClasses = computed(() => {
    return {
        'flex-col -mx-2.5': props.horizontal,
        'overflow-x-auto snap-x snap-mandatory': !props.horizontal && props.sliderOnDesktop,
        'md:flex-wrap overflow-x-auto snap-x snap-mandatory px-1.5 md:-mx-2.5': !props.horizontal && props.sliderOnMobile && displaySliderOnMobile,
        'flex-wrap -mx-2.5': !props.sliderOnDesktop && !props.sliderOnMobile
    }
})
const articleClasses = computed(() => {
    return {
        'p-2.5': true,
        'mb-6': !props.horizontal && props.articles && props.articles.length && props.articles.length > props.skeletonCount,
        'shrink-0 snap-always snap-center  w-[80vw]': !props.horizontal && props.sliderOnMobile && props.articles && props.articles.length > 1 && displaySliderOnMobile,
        'w-full': !props.sliderOnMobile && !displaySliderOnMobile.value,
        [props.articleWidth]: !props.horizontal
        // Add other dynamic classes here
    }
})

const slots = useSlots()

function setSliderPadding () {
    if (props.sliderOnDesktop) {
        sliderPadding.value = gridSpacer.value.offsetLeft
    }
}

const debouncedSetSliderPadding = debounce(setSliderPadding, 200)

onMounted(() => {
    if (props.sliderOnDesktop) {
        window.addEventListener('resize', debouncedSetSliderPadding)
    }
})

onUnmounted(() => {
    if (props.sliderOnDesktop) {
        window.removeEventListener('resize', debouncedSetSliderPadding)
    }
})
</script>

<template>
    <section>
        <div
            v-if="sliderOnDesktop"
            ref="gridSpacer"
            :class="{'container': contain}"
            class="flex w-full" />
        <ArticleSkeletonGrid
            v-if="!articles && skeletonCount > 0"
            :horizontal="horizontal"
            :contain="contain"
            :article-width="articleWidth"
            :has-description="(description?.length > 0)"
            :has-title="((title && title.length) || (url && url.length)) > 0"
            :number="skeletonCount"
            :slider-on-mobile="!horizontal && sliderOnMobile" />
        <div v-else>
            <div
                v-if="title || url || slots.title"
                :class="{'md:container px-4': contain, 'px-4 md:px-0': sliderOnMobile}"
                class="relative flex w-full items-start md:items-center">
                <div class="flex w-full flex-col">
                    <slot
                        v-if="slots.title && !title"
                        name="title" />
                    <SectionTitle
                        v-if="title && !slots.title"
                        :font-size="titleFontSize ? titleFontSize : (horizontal ? 'text-xl' : 'text-xl md:text-2xl')">
                        <div class="flex items-center">
                            <div
                                v-if="slots.icon"
                                class="mr-2">
                                <slot name="icon" />
                            </div>
                            {{ title }}
                        </div>
                    </SectionTitle>
                    <div
                        v-if="description"
                        class="mt-0 w-full max-w-2xl">
                        <SectionDescription>
                            {{ description }}
                        </SectionDescription>
                    </div>
                </div>
                <div
                    v-if="url"
                    class="flex shrink-0 items-center justify-end">
                    <Link
                        :href="url"
                        class="absolute top-1 left-0 flex h-9 w-full opacity-0 md:hidden">
                        <span class="sr-only">
                            Link to {{ title ?? 'more' }}
                        </span>
                    </Link>

                    <Link
                        v-if="allArticles.length > buttonLimit"
                        :href="url"
                        class="hidden text-sm font-bold transition-all hover:text-primary md:flex"
                        variant="primary">
                        {{ buttonText }}
                        <span class="sr-only">{{ title ?? 'articles' }}</span>
                        <ArrowRightIcon class="ml-2 w-5" />
                    </Link>

                    <ChevronRightIcon class="flex w-8 text-slate-900 md:hidden" />
                </div>
            </div>

            <div
                v-if="!horizontal"
                :class="[sliderOnDesktop ? '' : (contain ? (sliderOnMobile ? 'md:container' : 'container') : '')]">
                <div
                    v-if="allArticles.length"
                    :key="pagination ? pagination.current_page : null"
                    :class="[containerClasses, 'article-link-scroller']"
                    class="mt-0 flex items-stretch">
                    <div
                        v-for="article in allArticles"
                        :key="article.uuid"
                        :class="articleClasses">
                        <ArticleCard
                            :article="article"
                        />
                    </div>

                    <div
                        v-if="includeWritingCta"
                        class="hidden md:flex"
                        :class="articleClasses">
                        <CreateArticleLink
                            :text="writingCtaText" />
                    </div>

                    <div
                        v-if="allowAd && usePageProps().ads.enabled"
                        :class="articleClasses"
                        class="p-2.5 aspect-article-thumb">
                        <AdContainer
                            ad-format="fluid"
                            ad-layout-key="-6o+d7-38-3k+rl"
                            ad-slot="inArticlesGrid" />
                    </div>
                </div>
                <slot
                    v-if="allArticles.length === 0"
                    name="empty" />

                <Pagination
                    v-if="pagination"
                    :links="pagination.links" />
            </div>

            <div
                v-if="horizontal"
                :class="{'container': contain}">
                <div
                    v-if="allArticles.length"
                    :key="pagination ? pagination.current_page : null"
                    :class="[containerClasses, 'article-link-scroller']"
                    class="mt-0 flex items-stretch">
                    <div
                        v-for="article in allArticles"
                        :key="article.uuid"
                        :class="articleClasses"
                        class="p-2.5">
                        <ArticleCardHorizontal
                            :article="article" />
                    </div>

                    <div
                        v-if="allowAd && allArticles.length < buttonLimit && usePageProps().ads.enabled"
                        :class="articleClasses"
                        class="p-2.5">
                        <AdContainer
                            ad-format="fluid"
                            ad-layout-key="-6o+d7-38-3k+rl"
                            ad-slot="inArticlesGrid" />
                    </div>
                </div>
                <slot
                    v-if="allArticles.length === 0"
                    name="empty" />

                <Pagination
                    v-if="pagination"
                    :links="pagination.links" />
            </div>
        </div>
    </section>
</template>

<style scoped>
.article-link-scroller {
    @media (min-width: 768px) {
        padding-left: v-bind(sliderPadding);
        padding-right: v-bind(sliderPadding);
    }
}
</style>
