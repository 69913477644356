<script setup lang="ts">
import * as UserPermission from '@/Enums/UserPermission'
import Logo from '@/Components/Logo'
import useEmitter from '@/Composables/useEmitter.js'
import DashboardSidebarItem from '@/Components/Dashboard/DashboardSidebarItem.vue'
import { Link } from '@inertiajs/vue3'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import type { SidebarLink } from '@/Types/SidebarLink'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { usePermissions } from '@/Composables/usePermissions'
import { useMakeRequest } from '@/Composables/useMakeRequest.js'
import {
    ArrowTrendingUpIcon,
    ChatBubbleOvalLeftIcon,
    Cog6ToothIcon,
    DocumentTextIcon,
    HomeIcon,
    InboxStackIcon,
    PlusCircleIcon,
    BellIcon
} from '@heroicons/vue/20/solid'

const currentUrl = window.location.href

const notificationCount = ref(usePageProps().auth.notifications)
const notificationLink = computed<SidebarLink>(() => links.value.find(link => link.title === 'Notifications'))

const links = ref<SidebarLink[]>([
    {
        title: 'Dashboard',
        url: useRoute('dashboard.home'),
        active: false,
        icon: HomeIcon
    },
    {
        title: 'Notifications',
        url: useRoute('dashboard.user.notifications'),
        active: false,
        badge: notificationCount.value,
        icon: BellIcon,
        hasSeparator: true
    },
    {
        title: 'Moderate',
        url: useRoute('dashboard.moderate'),
        icon: InboxStackIcon,
        active: false,
        permission: usePermissions(UserPermission.MODERATE_ARTICLE)
    },
    {
        title: 'Articles',
        url: useRoute('dashboard.articles'),
        icon: DocumentTextIcon,
        active: false
    },
    {
        title: 'New article',
        url: useRoute('dashboard.article.create'),
        secondaryRoutes: ['articles.edit', 'dashboard.comments'],
        active: false,
        icon: PlusCircleIcon
    },
    {
        title: 'Comments',
        url: useRoute('dashboard.comments'),
        active: false,
        secondaryRoutes: 'dashboard.comments',
        icon: ChatBubbleOvalLeftIcon
    },
    {
        title: 'Following',
        url: useRoute('dashboard.following'),
        active: false,
        icon: PlusCircleIcon
    },
    {
        title: 'Analytics',
        url: useRoute('dashboard.analytics'),
        secondaryRoutes: ['dashboard.analytics', 'dashboard.articles.analytics'],
        active: false,
        icon: ArrowTrendingUpIcon
    }
])

const secondaryLinks = ref<SidebarLink[]>([
    {
        title: 'Settings',
        url: useRoute('dashboard.user.settings'),
        active: false,
        icon: Cog6ToothIcon
    },
    {
        title: usePageProps().auth.user.name,
        subtitle: 'Edit profile',
        url: useRoute('dashboard.user.edit'),
        active: false,
        icon: usePageProps().auth.user.avatars.small
    }
])

async function reloadNotificationsCount () {
    const response = await useMakeRequest(useRoute('api.notifications.count'), 'GET')
    notificationLink.value.badge = response.unreadCount
}

function setActiveLinks () {
    links.value.forEach(link => link.active = link.url === currentUrl || link.secondaryRoutes === useRoute().current() || link.secondaryRoutes?.includes(useRoute().current()))
    secondaryLinks.value.forEach(link => link.active = link.url === currentUrl || link.secondaryRoutes === useRoute().current() || link.secondaryRoutes?.includes(useRoute().current()))
}

onMounted(() => {
    setActiveLinks()
    useEmitter.on('notification.read', reloadNotificationsCount)
    useEmitter.on('clearNotifications', reloadNotificationsCount)
})

const emit = defineEmits(['close'])

function handleClick (link: SidebarLink) {
    links.value.forEach(link => link.active = false)
    secondaryLinks.value.forEach(link => link.active = false)
    link.active = true

    setTimeout(() => {
        emit('close')
    }, 100)
}
</script>

<template>
    <aside class="flex h-full grow flex-col overflow-y-auto px-0 text-white bg-slate-950 lg:bg-transparent">
        <div class="z-50 hidden w-full items-center justify-between pr-6 lg:flex">
            <Link
                v-tooltip="`Go back to ${usePageProps().app.name}`"
                :href="useRoute('home')"
                class="mt-4 flex h-14 shrink-0 items-center gap-4 px-5 lg:mt-4">
                <Logo.FullWhite class="hidden w-44 sm:w-40 lg:flex lg:w-48" />
            </Link>
        </div>
        <span
            class="mx-auto mt-2 mb-2 flex h-px w-full bg-slate-100/15" />
        <nav class="flex flex-1 flex-col justify-between pt-4 pb-2 lg:pt-2">
            <ul
                role="list"
                class="flex flex-col">
                <li
                    v-for="link in links"
                    :key="link.title">
                    <DashboardSidebarItem
                        :link="link"
                        @click="handleClick" />
                    <span
                        v-if="link.hasSeparator"
                        class="mx-auto my-4 flex h-px w-full bg-slate-100/15" />
                </li>
            </ul>
            <ul
                role="list"
                class="mt-auto flex flex-col">
                <li
                    v-for="link in secondaryLinks"
                    :key="link.title">
                    <DashboardSidebarItem
                        :link="link"
                        @click="handleClick" />
                </li>
            </ul>
        </nav>
    </aside>
</template>
