<script setup lang="ts">
import ArticleRatingsConfig from '@/Configs/ArticleRatings.ts'
import { ref, onMounted, type PropType } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import type { ArticleRating } from '@/Types/ArticleRating.js'
import { useMakeRequest } from '@/Composables/useMakeRequest.js'

const props = defineProps({
    uuid: {
        type: String
    },
    rating: {
        type: [Object as PropType<ArticleRating<any>>, null],
        default: null
    }
})

const displayRating = ref(props.rating)

function getComponent (rating: ArticleRating<any>) {
    return ArticleRatingsConfig.find(config => config.key === rating.type)?.component || null
}

const loading = ref(true)
const errored = ref(false)

async function fetchRating () {
    useMakeRequest(useRoute('api.ratings.single', props.uuid), 'GET')
        .then(response => {
            displayRating.value = response
            loading.value = false
        }).catch(error => {
            console.error(error)
            loading.value = false
            errored.value = true
        })
}

onMounted(() => {
    if (props.rating) {
        loading.value = false
    } else {
        fetchRating()
    }
})
</script>

<template>
    <div>
        <div v-if="errored">
            <p>There was an error fetching the rating.</p>
        </div>
        <div v-if="loading && !errored && !displayRating">
            <p>Loading...</p>
        </div>
        <div
            v-if="displayRating"
            class="not-prose">
            <component
                :is="getComponent(displayRating)"
                :rating="displayRating" />
        </div>
    </div>
</template>
