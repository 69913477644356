<script setup>
import Button from '@/Components/UI/Button.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import { ref } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import { useToast } from '@/Composables/useToast.ts'
import { NotificationType } from '@/Enums/NotificationType.ts'
import { useMakeRequest } from '@/Composables/useMakeRequest.js'

const props = defineProps({
    email: [String, null],
    subscription: Number
})

const actionTaken = ref(false)
const cancelled = ref(false)

function cancelSubscription () {
    actionTaken.value = true
    cancelled.value = true

    useMakeRequest(useRoute('newsletter.cancel.confirm', props.subscription), 'DELETE', {})
        .then(results => {
            if (results.success === true) {
                useToast('Successfully unsubscribed')
            } else {
                useToast('Something went wrong', NotificationType.FAIL)
            }
        })
}

function keepSubscription () {
    actionTaken.value = true
    cancelled.value = false
}
</script>

<template>
    <div
        class="px-4"
        itemscope
        itemtype="http://schema.org/Organization">
        <div
            class="mx-auto my-40 w-full max-w-2xl px-4">
            <SectionTitle
                font-size="text-xl md:text-4xl"
                class="mb-2 text-center">
                Cancel subscription
            </SectionTitle>
            <SectionDescription class="text-center text-balance">
                We are sorry to see you go. <br>Are you sure you want to cancel your subscription?
            </SectionDescription>

            <div class="mx-auto mt-6 flex items-center justify-center gap-4">
                <div
                    v-if="!actionTaken"
                    class="flex items-center gap-4">
                    <Button
                        variant="primary-light"
                        @click="keepSubscription">
                        Keep my subscription
                    </Button>
                    <Button
                        variant="danger"
                        @click="cancelSubscription">
                        Cancel subscription
                    </Button>
                </div>
                <Button
                    v-if="actionTaken"
                    :href="useRoute('home')">
                    Go home
                </Button>
            </div>
        </div>
    </div>
</template>
