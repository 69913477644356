import type Grade from '@/Types/Ratings/Grade'

type Config = {
    // eslint-disable-next-line no-unused-vars
    [key in Grade]: {
        text: string;
        bg: string;
    };
};

const config: Config = {
    S: {
        text: 'text-primary',
        bg: 'bg-primary'
    },
    'A-': {
        text: 'text-green-600',
        bg: 'bg-green-600'
    },
    A: {
        text: 'text-green-500',
        bg: 'bg-green-500'
    },
    'A+': {
        text: 'text-green-400',
        bg: 'bg-green-400'
    },
    'B-': {
        text: 'text-emerald-600',
        bg: 'bg-emerald-600'
    },
    B: {
        text: 'text-emerald-500',
        bg: 'bg-emerald-500'
    },
    'B+': {
        text: 'text-emerald-400',
        bg: 'bg-emerald-400'
    },
    'C-': {
        text: 'text-yellow-600',
        bg: 'bg-yellow-600'
    },
    C: {
        text: 'text-yellow-500',
        bg: 'bg-yellow-500'
    },
    'C+': {
        text: 'text-yellow-400',
        bg: 'bg-yellow-400'
    },
    'D-': {
        text: 'text-orange-600',
        bg: 'bg-orange-600'
    },
    D: {
        text: 'text-orange-500',
        bg: 'bg-orange-500'
    },
    'D+': {
        text: 'text-orange-400',
        bg: 'bg-orange-400'
    },
    'F-': {
        text: 'text-red-600',
        bg: 'bg-red-600'
    },
    F: {
        text: 'text-red-500',
        bg: 'bg-red-500'
    },
    'F+': {
        text: 'text-red-400',
        bg: 'bg-red-400'
    }
}

export default config
