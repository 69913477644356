<script setup lang="ts">
import Star from '@/Components/Icon/Star.vue'
import StarHalf from '@/Components/Icon/StarHalf.vue'
import StarEmpty from '@/Components/Icon/StarEmpty.vue'
import RatingContainer from '@/Components/Ratings/RatingContainer.vue'
import { type PropType } from 'vue'
import type { ArticleRating } from '@/Types/ArticleRating'

interface valueItem {
    value: number;
    title: string;
}

interface meta {
    values: valueItem[],
}

const props = defineProps({
    rating: Object as PropType<ArticleRating<meta>>
})

function fullStars (value: valueItem) {
    return Math.floor(value.value)
}

function hasHalfStar (value: valueItem) {
    return value.value % 1 !== 0
}

function emptyStars (value: valueItem) {
    return 5 - Math.ceil(value.value)
}

const classes = 'w-6'
</script>

<template>
    <RatingContainer :rating>
        <div
            v-for="value in rating.meta.values"
            :key="value.value"
            :class="{
                'w-full': rating.meta.values.length === 1,
                'md:w-1/2': rating.meta.values.length === 2,
                'md:w-1/3 my-2': rating.meta.values.length >= 3,
            }"
            class="flex w-full flex-col max-w-1/3 justify-center px-4 py-4 md:py-2">
            <div class="mx-auto flex items-center text-amber-400 gap-1.5">
                <Star
                    v-for="n in fullStars(value)"
                    :key="'full-' + n"
                    :class="classes" />
                <StarHalf
                    v-if="hasHalfStar(value)"
                    :class="classes" />
                <StarEmpty
                    v-for="n in emptyStars(value)"
                    :key="'empty-' + n"
                    :class="classes" />
            </div>
            <h2
                v-if="value.title"
                class="mt-2 block w-full break-words text-center text-lg font-bold leading-5 font-heading">
                {{ value.title }}
            </h2>
        </div>
    </RatingContainer>
</template>
