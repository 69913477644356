<script setup lang="ts">
import RatingType from '@/Enums/RatingType'
import EmojiSelector from '@/Components/UI/EmojiSelector.vue'
import BaseRatingForm from '@/Components/Ratings/BaseRatingForm.vue'
import type RatingFormComponent from '@/Types/Ratings/RatingFormComponent'
import { useForm } from '@inertiajs/vue3'
import type { RatingForm } from '@/Types/Ratings/RatingForm'

const props = defineProps<RatingFormComponent>()

interface EmojiValue {
    id: number | string;
    value: string;
    title: string;
}

const form = useForm<RatingForm<EmojiValue>>({
    rateable_type: props.rateableType,
    rateable_id: props.rateable.id,
    type: RatingType.EMOJI,
    title: '',
    description: '',
    meta: {
        values: [
            { id: 0, value: '1F600', title: '' }
        ]
    }
})
</script>

<template>
    <BaseRatingForm
        v-model:form="form"
        :default-value="{title: '', value: '1F600'}"
    >
        <template #element="{ element }">
            <div class="relative z-10">
                <EmojiSelector v-model="element.value" />
            </div>
        </template>
        <template #actions>
            <slot name="actions" />
        </template>
    </BaseRatingForm>
</template>
