import { type DefineComponent } from 'vue'
import EmojiRatingForm from '@/Components/Ratings/EmojiRatingForm.vue'
import StarRatingForm from '@/Components/Ratings/StarRatingForm.vue'
import ThumbRatingForm from '@/Components/Ratings/ThumbRatingForm.vue'
import NumberRatingForm from '@/Components/Ratings/NumberRatingForm.vue'
import SliderRatingForm from '@/Components/Ratings/SliderRatingForm.vue'
import EmojiRating from '@/Components/Ratings/EmojiRating.vue'
import StarRating from '@/Components/Ratings/StarRating.vue'
import ThumbRating from '@/Components/Ratings/ThumbRating.vue'
import NumberRating from '@/Components/Ratings/NumberRating.vue'
import SliderRating from '@/Components/Ratings/SliderRating.vue'
import GradeRating from '@/Components/Ratings/GradeRating.vue'
import GradeRatingForm from '@/Components/Ratings/GradeRatingForm.vue'

// Define the type for a single rating object
type RatingComponent = DefineComponent<{}, {}, any>

interface ArticleRating {
    key: string
    title: string
    component: RatingComponent
    form: RatingComponent
}

// Create the array of ratings with types
const ArticleRatingsConfig: ArticleRating[] = [
    {
        key: 'emoji',
        title: 'Emoji',
        component: EmojiRating,
        form: EmojiRatingForm
    },
    {
        key: 'star',
        title: 'Star',
        component: StarRating,
        form: StarRatingForm
    },
    {
        key: 'thumb',
        title: 'Thumbs',
        component: ThumbRating,
        form: ThumbRatingForm
    },
    {
        key: 'number',
        title: 'Number',
        component: NumberRating,
        form: NumberRatingForm
    },
    {
        key: 'slider',
        title: 'Slider',
        component: SliderRating,
        form: SliderRatingForm
    },
    {
        key: 'grade',
        title: 'Grade',
        component: GradeRating,
        form: GradeRatingForm
    }
]

export default ArticleRatingsConfig
