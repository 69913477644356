<script setup>
import Transition from '@/Components/Transition/index.js'
import ModalBackdrop from '@/Components/UI/ModalBackdrop.vue'
import ModalContainer from '@/Components/UI/ModalContainer.vue'
import { XMarkIcon } from '@heroicons/vue/20/solid'
import { useSlots, computed, onMounted, onUnmounted, watch } from 'vue'

const props = defineProps({
    show: {
        type: Boolean,
        default: false
    },
    centerVertical: {
        type: Boolean,
        default: false
    },
    maxWidth: {
        type: String,
        default: '2xl'
    },
    closeable: {
        type: Boolean,
        default: true
    },
    footer: {
        type: Boolean,
        default: true
    }
})

const emit = defineEmits(['close'])

watch(() => props.show, () => {
    if (props.show) {
        document.body.style.overflow = 'hidden'
    } else {
        document.body.style.overflow = null
    }
})

const close = () => {
    if (props.closeable) {
        emit('close')
    }
}

const closeOnEscape = (e) => {
    if (e.key === 'Escape' && props.show) {
        close()
    }
}

onMounted(() => document.addEventListener('keydown', closeOnEscape))

onUnmounted(() => {
    document.removeEventListener('keydown', closeOnEscape)
    document.body.style.overflow = null
})

const maxWidthClass = computed(() => {
    return {
        sm: 'sm:max-w-sm',
        md: 'sm:max-w-md',
        lg: 'sm:max-w-lg',
        xl: 'sm:max-w-xl',
        '2xl': 'sm:max-w-2xl',
        '3xl': 'sm:max-w-3xl',
        '4xl': 'sm:max-w-4xl'
    }[props.maxWidth]
})

const slots = useSlots()
</script>

<template>
    <teleport to="body">
        <Transition leave-active-class="duration-200">
            <div
                v-show="show"
                class="fixed inset-0 z-50 overflow-y-auto px-0"
                scroll-region>
                <ModalBackdrop
                    :show="show"
                    @click="close" />

                <Transition.FadeUp>
                    <ModalContainer
                        v-if="show"
                        :center-vertical="centerVertical"
                        :closeable="closeable"
                        :class="maxWidthClass"
                        @close="close">
                        <div>
                            <div class="relative px-6 pt-10 xs:pt-6">
                                <div
                                    v-if="slots.title"
                                    class="flex w-full items-center justify-between text-lg font-semibold text-slate-900 font-heading dark:text-white">
                                    <slot name="title" />
                                </div>
                                <button
                                    v-if="closeable"
                                    class="absolute top-4 right-4 hidden opacity-30 transition-all hover:opacity-100 xs:top-5 xs:right-6 sm:flex"
                                    @click="close">
                                    <XMarkIcon class="w-6" />
                                </button>

                                <div
                                    :class="slots.title ? 'mt-2' : ''"
                                    class="text-sm text-gray-600 dark:text-white/75">
                                    <slot name="content" />
                                </div>
                            </div>

                            <div
                                v-if="footer && slots.footer"
                                class="mt-4 flex flex-row justify-end px-6 py-4 empty:hidden">
                                <slot name="footer" />
                            </div>
                        </div>
                    </ModalContainer>
                </Transition.FadeUp>
            </div>
        </Transition>
    </teleport>
</template>
