<script setup>
import CircleProgress from 'vue3-circle-progress'
import { computed, onMounted, ref, watch } from 'vue'
import { PlusIcon, MinusIcon } from '@heroicons/vue/20/solid'
import { useModifyColour } from '@/Composables/useModifyColour.ts'

defineProps({
    hasInput: {
        type: Boolean,
        default: false
    },
    width: {
        type: Number,
        default: 180
    },
    title: {
        type: String,
        default: ''
    },
    speedOverride: {
        type: Number,
        default: null
    }
})

const displayScore = ref(0)
const colour = ref('var(--colors-primary)')

const value = defineModel('value', {
    type: Number,
    default: 0
})
const percentage = defineModel('percentage', {
    type: Boolean,
    default: false
})

const colours = [
    {
        name: 'primary',
        colour: 'var(--colors-primary)',
        min: null,
        max: null
    },
    {
        name: 'bad',
        colour: '#f87171',
        min: 0,
        max: 49
    },
    {
        name: 'medium',
        colour: '#fbbf24',
        min: 50,
        max: 89
    },
    {
        name: 'good',
        colour: '#4ade80',
        min: 90,
        max: 100
    }
]

function updateColours (score = value.value) {
    if (score === 0 || isNaN(score) || score === '') {
        colour.value = colours.find(c => c.name === 'bad').colour
        return
    }
    const selectedColour = colours.find(c => score >= c.min && score <= c.max)
    if (selectedColour) {
        colour.value = selectedColour.colour
    }
}

const modifiedColour = computed(() => useModifyColour(colour.value, -40))

const emit = defineEmits(['update:value', 'update:percentage'])

const inputValue = ref(value.value)

watch(inputValue, (value) => {
    if (value === '' || isNaN(value) || value < 0) {
        inputValue.value = 0
    } else if (value > 100) {
        inputValue.value = 100
    }
    displayScore.value = parseInt(value)
    updateFieldWidth()
    emit('update:value', displayScore.value)
    updateColours(displayScore.value)
})

const interval = ref(null)

function counterClick (action) {
    action()
    interval.value = setInterval(() => action(), 200)
}

function counterLetGo () {
    clearInterval(interval.value)
}

function add (amount) {
    inputValue.value = parseInt(inputValue.value) + amount
}

function subtract (amount) {
    inputValue.value = parseInt(inputValue.value) - amount
}

const numberInput = ref(null)

function togglePercentage () {
    percentage.value = !percentage.value
}

function updateFieldWidth () {
    const input = numberInput.value
    if (input) {
        let newWidth = inputValue.value.toString().length
        if (newWidth < 1) {
            newWidth = 1
        }
        input.style.width = `${newWidth}ch`
    }
}

onMounted(() => {
    updateColours()
    updateFieldWidth()
    setTimeout(() => {
        displayScore.value = value.value
    }, 200)
})
</script>

<template>
    <div class="mx-auto flex w-full">
        <div class="flex w-full flex-col">
            <div
                class="relative mx-auto flex justify-center"
                :style="{width: width + 'px'}">
                <CircleProgress
                    :border-bg-width="8"
                    :border-width="8"
                    :fill-color="colour"
                    :percent="displayScore"
                    :size="width"
                    :transition="speedOverride || 500"
                    empty-color="rgba(0,0,0,0.05)"
                />

                <div
                    v-if="hasInput"
                    :class="width < 130 ? 'left-2' : 'left-2.5'"
                    class="absolute top-1/2 -translate-y-1/2">
                    <button
                        type="button"
                        class="p-1 opacity-75 text-primary hover:opacity-100"
                        @mousedown="counterClick(() => subtract(1))"
                        @mouseup="counterLetGo">
                        <MinusIcon class="w-5" />
                    </button>
                </div>
                <div
                    v-if="hasInput"
                    :class="width < 130 ? 'right-2' : 'right-2.5'"
                    class="absolute top-1/2 -translate-y-1/2">
                    <button
                        type="button"
                        class="p-1 opacity-75 text-primary hover:opacity-100"
                        @mousedown="counterClick(() => add(1))"
                        @mouseup="counterLetGo">
                        <PlusIcon class="w-5" />
                    </button>
                </div>

                <div
                    v-if="hasInput"
                    class="absolute top-3/4 left-1/2 flex -translate-x-1/2 -translate-y-2/3 items-center justify-between gap-4 text-xl text-primary px-0.5">
                    <button
                        v-tooltip="percentage ? 'Switch to number' : 'Switch to percentage'"
                        type="button"
                        @click="togglePercentage">
                        <span
                            :class="percentage ? 'bg-primary border-white text-white' : 'bg-white text-primary border-primary'"
                            class="rounded-full border py-1 text-xs px-2.5">
                            %
                        </span>
                    </button>
                </div>

                <span
                    v-if="!hasInput"
                    :style="{ color: modifiedColour }"
                    :class="width >= 140 ? 'text-3xl' : 'text-xl'"
                    class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-bold text-primary"
                    v-html="value + (percentage ? '%' : '')"
                />
                <div
                    v-if="hasInput"
                    :style="{ color: modifiedColour }"
                    :class="width >= 140 ? 'text-2xl' : 'text-lg'"
                    class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center font-bold text-primary">
                    <input
                        ref="numberInput"
                        v-model="inputValue"
                        type="number"
                        :min="0"
                        :max="100"
                        class="bg-transparent outline-none number-appearance-none"
                    >
                    <span
                        v-if="percentage"
                        class="-ml-0.5">%</span>
                </div>
            </div>
            <h3
                v-if="title"
                :style="{ color: modifiedColour }"
                class="mt-4 block w-full break-words text-center text-lg font-bold leading-5 font-heading"
            >
                {{ title }}
            </h3>
        </div>
    </div>
</template>
