<script setup lang="ts">
import 'vue3-emoji-picker/css'
import Dropdown from '@/Components/UI/Dropdown.vue'
import EmojiRenderer from '@/Components/UI/EmojiRenderer.vue'
import EmojiPicker, { type EmojiExt } from 'vue3-emoji-picker'
import { ref } from 'vue'

defineProps({
    modelValue: String
})

const emit = defineEmits(['update:modelValue'])
const selectedEmoji = ref('')
const dropdown = ref(null)

function onSelectEmoji (emoji: EmojiExt) {
    emit('update:modelValue', emoji.u)
    selectedEmoji.value = emoji.u
    dropdown.value.close()
}
</script>

<template>
    <Dropdown
        ref="dropdown"
        menu-width="w-[280px] max-w-[90vw]"
        list-classes="p-0"
        toggle-classes="mx-auto rounded-md min-w-[45px] text-center"
        class="relative z-20">
        <template #toggle>
            <div class="w-full text-6xl">
                <EmojiRenderer
                    v-if="modelValue"
                    :unicode="modelValue" />
                <p v-else>
                    😀
                </p>
            </div>
        </template>
        <template #items>
            <div>
                <EmojiPicker
                    :disable-skin-tones="true"
                    :display-recent="true"
                    :hide-group-icons="true"
                    :disabled-groups="['flags', 'travel_places', 'objects', 'symbols', 'activities']"
                    :native="true"
                    @select="onSelectEmoji" />
            </div>
        </template>
    </Dropdown>
</template>
