<script setup lang="ts">
import Button from '@/Components/UI/Button.vue'
import UserAvatar from '@/Components/User/UserAvatar.vue'
import InputTextarea from '@/Components/Input/InputTextarea.vue'
import MentionableTextarea from '@/Components/UI/MentionableTextarea.vue'
import InputImageSelector from '@/Components/Input/InputImageSelector.vue'
import { ref } from 'vue'
import { Mentionable } from 'vue-mention'
import { useForm } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { useMakeRequest } from '@/Composables/useMakeRequest'

const imageSelectorKey = ref<number>(0)

interface FormData {
    content: string;
    images: { file: File, url: string | ArrayBuffer | null }[];
}

const form = useForm<FormData>({
    content: '',
    images: []
})

const emit = defineEmits(['reload'])

const submit = () => {
    form.post(useRoute('posts.store'), {
        preserveScroll: true,
        onSuccess: () => {
            reset()
            emit('reload')
        }
    })
}

function reset () {
    form.reset()
    form.clearErrors()
    imageSelectorKey.value++
}

const limit = 6

const handleDrop = (event: DragEvent) => {
    const files = event.dataTransfer?.files
    if (files && files.length > 0) {
        processFiles(files)
    }
}

const imageSelector = ref<InstanceType<typeof InputImageSelector> | null>(null)

const processFiles = (files: FileList) => {
    const currentImageCount = form.images.length
    const allowedFilesCount = Math.min(files.length, limit - currentImageCount)

    for (let i = 0; i < allowedFilesCount; i++) {
        const file = files[i]
        if (!file.type.startsWith('image/') || file.type === 'image/svg+xml') {
            continue
        }

        const reader = new FileReader()
        reader.onload = (e: ProgressEvent<FileReader>) => {
            form.images.push({ file, url: e.target?.result })
        }
        reader.readAsDataURL(file)
    }
}

function handleEnter () {
    if (!form.processing && (form.content)) {
        submit()
    }
}
</script>

<template>
    <form
        @submit.prevent="submit()"
        @dragover.prevent
        @drop.prevent="handleDrop">
        <MentionableTextarea
            v-model="form.content"
            :error="form.errors?.content"
            placeholder="What's happening"
            @enter="handleEnter"
        />

        <div class="flex pt-2 pl-2">
            <InputImageSelector
                ref="imageSelector"
                :key="imageSelectorKey"
                v-model="form.images"
                :limit="limit" />
        </div>
        <div
            class="ml-auto flex w-full items-center justify-end gap-2"
            :class="[form.images.length > 0 ? '' : '-mt-9']">
            <Button
                v-if="form.isDirty"
                variant="white"
                type="button"
                :loading="form.processing"
                @click="reset">
                Cancel
            </Button>
            <Button
                type="button"
                :loading="form.processing"
                @click="submit">
                Submit
            </Button>
        </div>
    </form>
</template>
