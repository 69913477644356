<script setup lang="ts">
import PostList from '@/Components/Post/PostList.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import { type PropType, ref } from 'vue'
import { Link, router } from '@inertiajs/vue3'
import type { Post } from '@/Types/Models/Post'
import type { User } from '@/Types/Models/User'
import { useRoute } from '@/Composables/useRoute.ts'
import { ArrowRightIcon, ChevronRightIcon } from '@heroicons/vue/20/solid'

defineProps({
    posts: Object as PropType<Post[]>,
    user: Object as PropType<User>,
    hasPosts: Boolean,
    title: {
        type: String,
        default: 'Latest posts'
    },
    description: {
        type: [String, null],
        default: null
    }
})

const postsKey = ref(0)

function reload () {
    router.reload({
        only: ['posts'],
        onSuccess: () => {
            postsKey.value++
        }
    })
}

defineOptions({
    inheritAttrs: false
})
</script>

<template>
    <div v-if="hasPosts">
        <div class="flex max-w-2xl items-center">
            <div class="flex w-full flex-col mb-2.5">
                <SectionTitle
                    v-if="title"
                    font-size="text-xl md:text-2xl">
                    <div class="flex items-center">
                        {{ title }}
                    </div>
                </SectionTitle>
                <div
                    v-if="description"
                    class="mt-0 w-full max-w-2xl">
                    <SectionDescription>
                        {{ description }}
                    </SectionDescription>
                </div>
            </div>
            <div
                class="flex shrink-0 items-center justify-end mb-2.5">
                <Link
                    :href="useRoute('user.show.posts', user)"
                    class="absolute top-1 left-0 flex h-9 w-full opacity-0 md:hidden">
                    <span class="sr-only">
                        Link to {{ title ?? 'more' }}
                    </span>
                </Link>

                <Link
                    :href="useRoute('user.show.posts', user)"
                    class="hidden text-sm font-bold transition-all hover:text-primary md:flex"
                    variant="primary">
                    See more <span class="sr-only">posts</span>
                    <ArrowRightIcon class="ml-2 w-5" />
                </Link>

                <ChevronRightIcon class="flex w-8 text-slate-900 md:hidden" />
            </div>
        </div>
        <PostList
            v-if="posts"
            :key="postsKey"
            class="max-w-2xl"
            :user="user"
            :include-form="true"
            :paginated="false"
            :posts="posts"
            @reload="reload" />
    </div>
</template>
