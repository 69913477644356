<template>
    <svg
        enable-background="new 0 0 24 24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fill="currentColor"
            d="m5.449 15.293-1.237 7.586c-.102.622.562 1.078 1.103.777l6.412-3.544 1.661.918c.859.477 1.603-.828.726-1.312l-1.614-.892v-15.608c.108.057.227.093.35.093.107 0 .215-.022.318-.071.375-.176.536-.623.36-.998l-.85-1.811c-.231-.491-.956-.63-1.303-.072-.283.445-1.221 2.344-3.534 7.202l-7.204 1.101c-.606.093-.848.83-.423 1.265z" />
        <path
            fill="currentColor"
            d="m17.455 9.208c.364 0 .684-.266.74-.637.062-.41-.219-.792-.628-.854l-1.582-.242-.68-1.448c-.176-.375-.624-.536-.998-.36-.375.176-.536.623-.36.998l.85 1.811c.106.226.318.385.565.422l1.978.302c.039.006.077.008.115.008z" />
        <path
            fill="currentColor"
            d="m21.33 11.313c.146.142.335.213.524.213.195 0 .39-.076.537-.226l1.396-1.432c.196-.202.263-.496.174-.762s-.32-.46-.598-.503l-1.977-.302c-.41-.062-.792.219-.854.628-.063.409.218.792.628.854l.535.082-.378.387c-.29.297-.284.771.013 1.061z" />
        <path
            fill="currentColor"
            d="m19.545 13.141c-.296-.288-.771-.282-1.061.013l-1.396 1.432c-.166.17-.241.41-.203.645l.322 1.974c.06.368.378.629.739.629.04 0 .081-.003.122-.01.409-.066.686-.452.62-.861l-.261-1.601 1.132-1.161c.289-.296.283-.771-.014-1.06z" />
        <path
            fill="currentColor"
            d="m16.84 22.938 1.75.968c.54.3 1.205-.154 1.103-.777l-.322-1.974c-.067-.409-.455-.688-.861-.62-.409.067-.687.452-.62.861l.073.45-.398-.22c-.36-.199-.819-.07-1.019.293s-.069.819.294 1.019z" />
    </svg>
</template>
