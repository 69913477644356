<script setup>
import Star from '@/Components/Icon/Star.vue'
import StarHalf from '@/Components/Icon/StarHalf.vue'
import StarEmpty from '@/Components/Icon/StarEmpty.vue'
import { ref, computed } from 'vue'

defineProps({
    max: {
        type: Number,
        default: 5
    }
})

const rawRating = defineModel('modelValue', {
    type: Number,
    default: 0
})

const hover = ref(0)

const roundToNearestHalf = (value) => {
    return Math.round(value * 2) / 2
}

const rating = computed({
    get () {
        return rawRating.value ? roundToNearestHalf(Number(rawRating.value)) : 0
    },
    set (value) {
        rawRating.value = roundToNearestHalf(Number(value))
    }
})

const handleMouseMove = (event, index) => {
    const rect = event.currentTarget.getBoundingClientRect()
    const halfWidth = rect.width / 2
    const isHalfStar = event.clientX - rect.left < halfWidth
    hover.value = index + (isHalfStar ? 0.5 : 1)
}

const handleMouseLeave = () => {
    hover.value = 0
}

const handleClick = (index) => {
    rating.value = hover.value === rating.value ? 0 : hover.value
}

const classes = 'w-6'
</script>

<template>
    <div class="flex flex-col items-center space-y-2">
        <div
            class="flex"
            role="radiogroup"
            aria-label="Rate your experience">
            <button
                v-for="index in max"
                :key="index"
                type="button"
                class="text-amber-400 px-[0.1875rem] focus:outline-none"
                :aria-label="`${index} star${index !== 1 ? 's' : ''}`"
                :aria-checked="rating === index"
                role="radio"
                @mousemove="handleMouseMove($event, index - 1)"
                @mouseleave="handleMouseLeave"
                @click="handleClick(index)"
            >
                <template v-if="hover !== 0 ? hover >= index : rating >= index">
                    <Star :class="classes" />
                </template>
                <template v-else-if="hover !== 0 ? hover === index - 0.5 : rating === index - 0.5">
                    <StarHalf :class="classes" />
                </template>
                <template v-else>
                    <StarEmpty :class="classes" />
                </template>
            </button>
        </div>
    </div>
</template>
