<script setup lang="ts">
import Button from '@/Components/UI/Button.vue'
import Novus from '@/Components/Global/Novus.vue'
import type { Game } from '@/Types/Models/Game'
import { onMounted, type PropType, ref } from 'vue'
import type { Article } from '@/Types/Models/Article'
import { useCookies } from '@vueuse/integrations/useCookies'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid'

const props = defineProps({
    article: Object as PropType<Article>,
    game: Object as PropType<Game>
})

const show = ref(false)
const cookies = useCookies()

const cookieKey = 'article:spoiler_hide:' + props.article.uuid

function close () {
    cookies.set(cookieKey, true, {
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 5))
    })
    show.value = !show.value
    document.body.classList.remove('overflow-hidden')
}

onMounted(() => {
    if (!cookies.get(cookieKey)) {
        show.value = true
        document.body.classList.add('overflow-hidden')
    }
})
</script>

<template>
    <div>
        <Transition name="spoiler">
            <div
                v-show="show"
                class="fixed top-0 left-0 isolate z-10 flex h-full w-full items-center justify-center bg-white/75 backdrop-blur-lg">
                <Novus
                    emotion="shocked"
                    class="absolute -right-4 -bottom-4 w-56 -rotate-12" />
                <div class="flex flex-col items-center">
                    <ExclamationTriangleIcon class="w-16 text-orange-500" />
                    <h2 class="mt-4 text-4xl font-heading">
                        Spoilers ahead!
                    </h2>
                    <div class="mt-2 flex flex-col items-center text-sm text-slate-950">
                        <p v-if="game">
                            This article may contain spoilers about <a
                                class="italic text-primary hover:underline"
                                :href="game.permalink"
                                target="_blank">{{ game.name }}</a>.
                        </p>
                        <p v-else>
                            This article may contain spoilers.
                        </p>
                        <p>
                            Proceed with caution.
                        </p>
                    </div>
                    <div class="mt-8 flex justify-center gap-4">
                        <Button variant="secondary">
                            Go home
                        </Button>
                        <Button @click="close">
                            Continue on
                        </Button>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<style scoped>
.spoiler-leave-active {
    transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.spoiler-leave-to {
    transform: scale(1.2);
    opacity: 0;
}
</style>
