export function useModifyColour (hexColor: string, magnitude: number): string {
    hexColor = hexColor.replace('#', '')
    if (hexColor.length === 6) {
        const decimalColor = parseInt(hexColor, 16)
        let r = (decimalColor >> 16) + magnitude
        let g = ((decimalColor >> 8) & 0x00ff) + magnitude
        let b = (decimalColor & 0x0000ff) + magnitude
        r = Math.min(255, Math.max(0, r))
        g = Math.min(255, Math.max(0, g))
        b = Math.min(255, Math.max(0, b))
        return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}`
    }
    return hexColor
}
