<script setup lang="ts">
defineProps({
    closeable: {
        type: Boolean,
        default: true
    },
    centerVertical: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['close'])
</script>

<template>
    <div
        :class="centerVertical ? 'sm:top-1/4' : 'sm:top-16'"
        class="sm:mb-6 overflow-auto max-h-[calc(100dvh-5rem)] w-full sm:relative rounded-t-2xl sm:rounded-xl bottom-0 absolute dark:bg-slate-900 dark:text-white bg-white shadow-lg ring-1 ring-slate-950/10 transition-all sm:mx-auto sm:w-full">
        <button
            v-if="closeable"
            type="button"
            class="absolute top-2 left-1/2 z-50 flex h-2 w-12 -translate-x-1/2 rounded-full bg-slate-950/10 dark:bg-white/10 sm:hidden"
            @click="emit('close')" />
        <slot />
    </div>
</template>
