<script setup>
import ErrorText from '@/Components/UI/ErrorText.vue'
import InputLabel from '@/Components/Input/InputLabel.vue'
import { Switch, SwitchDescription, SwitchGroup } from '@headlessui/vue'

const enabled = defineModel({
    type: Boolean
})

defineProps({
    disabled: { type: Boolean },
    textFirst: {
        type: Boolean,
        default: false
    },
    label: {
        type: String,
        default: null
    },
    description: {
        type: String,
        default: null
    },
    error: {
        type: String,
        default: null
    }
})
</script>

<template>
    <SwitchGroup
        as="label"
        class="flex items-center justify-between">
        <span class="flex flex-grow flex-col">
            <InputLabel
                :is-label="false"
                :margin="null"
                passive>{{ label }}</InputLabel>
            <SwitchDescription
                v-if="description"
                as="span"
                class="select-none text-xs text-slate-400">
                {{ description }}
            </SwitchDescription>

            <ErrorText
                v-if="error"
                :error="error" />
        </span>
        <Switch
            v-model="enabled"
            :class="[enabled ? 'bg-primary' : 'bg-slate-200', 'relative inline-flex h-5 w-9 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2']">
            <span
                aria-hidden="true"
                :class="[enabled ? 'translate-x-4' : 'translate-x-0', 'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
        </Switch>
    </SwitchGroup>
</template>
