enum Status {
    PUBLISHED = 'published',
    DRAFTS = 'drafts',
    SUBMITTED = 'submitted',
    IN_REVIEW = 'in review',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    TRASHED = 'trashed'
}

export default Status
