import { useRoute } from '@/Composables/useRoute.ts'
import type { User } from '@/Types/Models/User'
import type { TaggedUser } from '@/Types/TaggedUser'

export function useFormatUserTags (content: string, taggedUsers: TaggedUser[]) {
    const checkedUsers = new Set()

    taggedUsers.forEach(taggedUser => {
        const user = taggedUser.user
        const string = taggedUser.string
        const usernamePattern = new RegExp(`(?<![:\\/])@${string}(\\b)`, 'g')

        if (!checkedUsers.has(user.username)) {
            content = content.replace(
                usernamePattern,
                `<a href="${useRoute('user.show', user)}" class="text-primary hover:underline">@${user.username}</a>`
            )
            checkedUsers.add(user.username)
        }
    })

    return content
}
