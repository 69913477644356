<script setup lang="ts">
import Card from '@/Components/UI/Card.vue'
import Badge from '@/Components/UI/Badge.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import AccordionTransition from '@/Components/Transition/AccordionTransition.vue'
import { type PropType, ref } from 'vue'
import { useDateFormat } from '@vueuse/core'
import { ChevronUpIcon } from '@heroicons/vue/24/outline'
import { usePageProps } from '@/Composables/usePageProps.ts'

const showRejections = ref(false)

interface RejectionReason {
    date: string;
    reason: string;
}

defineProps({
    rejectionReasons: Array as PropType<RejectionReason[]>
})
</script>

<template>
    <Card v-if="rejectionReasons.length > 0">
        <SectionTitle font-size="text-xl">
            <button
                type="button"
                class="flex w-full items-center justify-between"
                @click="showRejections = !showRejections">
                <span class="flex items-center gap-2">
                    Previous rejections
                    <Badge
                        colour="red"
                        :text="rejectionReasons.length" />
                </span>
                <span>
                    <ChevronUpIcon
                        class="w-4 transition-transform"
                        :class="!showRejections ? 'transform rotate-180' : 'transform rotate-0'"
                    />
                </span>
            </button>
        </SectionTitle>
        <AccordionTransition>
            <ul
                v-show="showRejections"
                class="flex flex-col gap-4 py-4 text-sm text-red-500">
                <li
                    v-for="(rejectionReason, i) in rejectionReasons"
                    :key="i"
                    class="flex flex-col gap-0">
                    <strong class="font-mono">
                        {{ useDateFormat(rejectionReason.date, "YYYY-MM-DD hh:mma", { locales: usePageProps().app.locale }).value }}
                    </strong>
                    <p>{{ rejectionReason.reason }}</p>
                </li>
            </ul>
        </AccordionTransition>
    </Card>
</template>
