<script setup lang="ts">
import Modal from '@/Components/UI/Modal.vue'
import Button from '@/Components/UI/Button.vue'
import FsLightbox from '@/Plugins/fslightbox/v3'
import useEmitter from '@/Composables/useEmitter'
import TagList from '@/Components/Tag/TagList.vue'
import GameCard from '@/Components/Game/GameCard.vue'
import ModelMapReverse from '@/Configs/ModelMapReverse.ts'
import Screenshots from '@/Components/Game/Screenshots.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import GameActions from '@/Components/Game/GameActions.vue'
import RelatedGames from '@/Components/Game/RelatedGames.vue'
import ArticleGrid from '@/Components/Article/ArticleGrid.vue'
import CommentList from '@/Components/Comment/CommentList.vue'
import GameDataTable from '@/Components/Game/GameDataTable.vue'
import GameThumbnail from '@/Components/Game/GameThumbnail.vue'
import EmptyStateAlt from '@/Components/Global/EmptyStateAlt.vue'
import GameWebsiteLinks from '@/Components/Game/GameWebsiteLinks.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import ArticleCreateButton from '@/Components/Article/ArticleCreateButton.vue'
import type { Tag } from '@/Types/Models/Tag'
import { router, Link } from '@inertiajs/vue3'
import type { Game } from '@/Types/Models/Game'
import type { Genre } from '@/Types/Models/Genre'
import { RssIcon } from '@heroicons/vue/24/solid'
import type { ImageUrls } from '@/Types/ImageUrls'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Article } from '@/Types/Models/Article'
import type { Platform } from '@/Types/Models/Platform'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import { useMakeRequest } from '@/Composables/useMakeRequest'
import { nextTick, onMounted, type PropType, ref } from 'vue'

const props = defineProps({
    game: Object as PropType<Game>,
    parent: Object as PropType<Game>,
    primaryGenre: [Object as PropType<Genre>, null],
    initialComments: Array as PropType<Comment[]>,
    initialCommentsCount: Number,
    hasTags: Boolean,
    hasArticles: Boolean,
    hasRelatedGames: Boolean,
    hasChildren: Boolean,
    alternativeNames: Array as PropType<string[]>,
    screenshots: Array as PropType<ImageUrls[]>,
    relatedGames: Array as PropType<Game[]>,
    children: Array as PropType<Game[]>,
    genres: Array as PropType<Genre[]>,
    tags: Array as PropType<Tag[]>,
    platforms: Array as PropType<Platform[]>,
    articles: Array as PropType<Article[]>
})

const moreInfoModalOpen = ref<boolean>(false)
const showCoverLightbox = ref<boolean>(false)
const showScreenshotLightbox = ref<boolean>(false)
const screenshotLightboxSelected = ref<number>(0)
const allArticles = ref<Article[]>(props.articles)

const limitedRelatedGames = ref<Game[]>(props.relatedGames)
const limitedChildrenGames = ref<Game[]>(props.children)
const comments = ref<Comment[]>(props.initialComments)
const commentsCount = ref<number>(props.initialCommentsCount)

function openLightboxOnSlide (number: number) {
    number = number + 1
    screenshotLightboxSelected.value = number
    showScreenshotLightbox.value = !showScreenshotLightbox.value
}

function lazyload () {
    router.reload({
        only: ['relatedGames', 'platforms', 'children', 'tags', 'genres', 'articles'],
        onSuccess: () => {
            limitedRelatedGames.value = props.relatedGames ? props.relatedGames.slice(0, 6) : []
            limitedChildrenGames.value = props.children.slice(0, 3)
            allArticles.value = props.articles

            const hash = window.location.hash
            const commentId = hash.substring(1)
            const element = document.getElementById(commentId)

            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' })
                element.classList.add('highlight')
            }
        }
    })
}

function loadComments () {
    useMakeRequest(useRoute('api.game.comments', props.game.slug), 'POST').then(response => {
        comments.value = response.comments
        commentsCount.value = response.commentsCount
    })
}

onMounted(() => {
    loadComments()
    nextTick(() => {
        lazyload()
    })

    useEmitter.on('comment:created', () => {
        loadComments()
    })

    useEmitter.on('comment:deleted', () => {
        loadComments()
    })
})
</script>

<template>
    <div>
        <div
            v-lazy:background-image="game.featured_image"
            class="relative flex h-auto bg-cover bg-center px-4 py-8 bg-primary md:mx-4 md:mt-4 md:flex md:rounded-xl md:px-0 xl:h-128">
            <div
                class="absolute top-0 left-0 h-full w-full bg-slate-900/30 backdrop-blur-2xl md:rounded-xl" />
            <div class="relative flex h-full flex-col justify-center text-white md:container xl:h-auto xl:pt-36">
                <div class="mx-auto flex w-full flex-col xl:max-w-7xl xl:flex-row xl:pt-20">
                    <div class="flex w-full flex-col items-start md:flex-row md:gap-8 lg:gap-12">
                        <div class="flex w-1/3 xl:shrink-0">
                            <GameThumbnail
                                :game
                                size="large"
                                class="shadow-xl md:rounded-xl"
                            />
                        </div>
                        <div class="w-full pt-4 md:w-2/3 lg:pt-1">
                            <div class="flex flex-col-reverse justify-between gap-2 md:mb-2 md:flex-row md:items-center md:gap-0">
                                <div class="mb-1 flex flex-wrap items-center gap-1 text-sm">
                                    <Link
                                        class="hover:underline"
                                        :href="useRoute('games.index')">
                                        Games
                                    </Link>
                                    <ChevronRightIcon class="h-4 w-4" />
                                    <Link
                                        v-if="primaryGenre"
                                        class="hover:underline"
                                        :href="useRoute('genres.show', primaryGenre.slug)">
                                        {{ primaryGenre.name }}
                                    </Link>
                                    <Link
                                        v-else
                                        class="hover:underline"
                                        :href="game.permalink">
                                        {{ game.name }}
                                    </Link>
                                </div>
                                <GameActions :game="game" />
                            </div>
                            <SectionTitle font-size="text-xl sm:text-3xl md:text-4xl">
                                {{ game.name }}
                            </SectionTitle>
                            <h2
                                v-if="game.release_year"
                                class="text-sm font-semibold mt-0.5">
                                Released: {{ game.release_year }}
                            </h2>
                            <div
                                class="-mx-2 cursor-pointer rounded-lg bg-transparent p-2 transition-all text-balance hover:bg-white/10"
                                @click="moreInfoModalOpen = true">
                                <SectionDescription class="text-white line-clamp-3 xl:line-clamp-3">
                                    {{ game.description }}
                                </SectionDescription>
                            </div>

                            <Screenshots
                                class="hidden lg:block"
                                :screenshots="screenshots"
                                @screenshot-click="openLightboxOnSlide" />
                            <GameWebsiteLinks
                                :game="game"
                                class="mt-2" />
                        </div>
                    </div>

                    <Screenshots
                        class="-mx-4 mt-4 block lg:hidden"
                        :screenshots="screenshots"
                        @screenshot-click="openLightboxOnSlide" />
                </div>
            </div>
        </div>

        <div class="mb-8 lg:container">
            <div class="mx-auto flex max-w-7xl flex-col items-start gap-12 lg:flex-row">
                <div class="flex w-full flex-col gap-2 overflow-x-hidden px-4 lg:w-1/3 lg:px-0 xl:pt-36">
                    <GameDataTable
                        class="pt-4"
                        :platforms
                        :genres
                        :game
                        @open-modal="moreInfoModalOpen = true" />

                    <TagList
                        v-if="hasTags"
                        class="p-0.5"
                        :center="false"
                        :small="true"
                        :tags />

                    <div
                        v-if="parent"
                        class="mt-4">
                        <SectionTitle>Parent game</SectionTitle>
                        <div class="mt-2 flex items-start">
                            <GameCard
                                :game="parent"
                                class="w-1/3"
                                game-title-size="text-base"
                                :display-description="false"
                                :display-release-date="false"
                            />
                        </div>
                    </div>

                    <div
                        v-if="hasRelatedGames"
                        class="mt-4">
                        <RelatedGames
                            title="Related games"
                            :display-description="false"
                            :display-release-date="false"
                            :games="limitedRelatedGames" />
                    </div>

                    <div
                        v-if="hasChildren"
                        class="mt-4">
                        <RelatedGames
                            title="DLCs and Expansions"
                            :display-description="false"
                            :display-release-date="false"
                            :games="limitedChildrenGames" />
                    </div>
                </div>
                <div class="w-full px-4 lg:w-2/3 lg:pt-8">
                    <ArticleGrid
                        :contain="false"
                        :articles="allArticles"
                        :skeleton-count="2"
                        article-width="md:w-1/2 lg:w-1/2 xl:w-1/2">
                        <template #title>
                            <div class="flex items-center justify-between">
                                <SectionTitle>
                                    Articles
                                </SectionTitle>
                                <a
                                    class="flex items-center gap-1 text-sm group"
                                    target="_blank"
                                    :href="useRoute('rss.game', game.slug)">
                                    <RssIcon class="w-4 text-slate-900/75 dark:text-white/75 transition-colors group-hover:text-[#f26522]" />
                                    <span class="text-slate-900/75 group-hover:underline dark:text-white/75">Feed</span>
                                </a>
                            </div>
                        </template>
                        <template #empty>
                            <EmptyStateAlt
                                title="There's nothing here"
                                :description="`Try writing your own!`">
                                <template #button>
                                    <ArticleCreateButton
                                        :append="{game: game.identifier}"
                                        class="justify-center" />
                                </template>
                            </EmptyStateAlt>
                        </template>
                    </ArticleGrid>

                    <div class="mt-8 px-4">
                        <CommentList
                            id="comments"
                            :form-data="{
                                commentable_id: game.identifier,
                                commentable_type: ModelMapReverse['Game']
                            }"
                            :commentable="game"
                            :comments="comments"
                            :count="commentsCount" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <FsLightbox
        v-if="screenshots && screenshots.length"
        :show-thumbs-on-mount="true"
        :slide="screenshotLightboxSelected"
        :sources="screenshots.map(screenshot => screenshot.full_webp)"
        :thumbs="screenshots.map(screenshot => screenshot.small_webp)"
        :toggler="showScreenshotLightbox" />

    <FsLightbox
        v-if="game.cover"
        :show-thumbs-on-mount="true"
        :sources="[game.cover.full_webp]"
        :thumbs="[game.cover.medium_webp]"
        :toggler="showCoverLightbox" />

    <Modal
        :show="moreInfoModalOpen"
        @close="moreInfoModalOpen = false">
        <template #title>
            {{ game.name }}
        </template>
        <template #content>
            <div class="max-w-none text-sm prose">
                <p
                    class="mb-6"
                    v-html="game.description" />

                <div v-if="game.storyline">
                    <h3>
                        Storyline
                    </h3>
                    <p
                        class="mb-6"
                        v-html="game.storyline" />
                </div>

                <div v-if="alternativeNames && alternativeNames.length">
                    <h3>
                        Alternative names
                    </h3>
                    <ul>
                        <li
                            v-for="name in alternativeNames"
                            :key="name">
                            {{ name }}
                        </li>
                    </ul>
                </div>

                <div v-if="game.companies && game.companies.length">
                    <h3>
                        All involved companies
                    </h3>
                    <ul>
                        <li
                            v-for="company in game.companies"
                            :key="company.name">
                            <component
                                :is="company.url ? 'a' : 'span'"
                                :href="company.url"
                                target="_blank"
                                rel="nofollow"
                                :class="company.url ? 'hover:underline' : ''">
                                {{ company.name }}
                            </component>
                        </li>
                    </ul>
                </div>

                <div v-if="platforms && platforms.length">
                    <h3>
                        Platforms
                    </h3>
                    <ul>
                        <li
                            v-for="platform in platforms"
                            :key="platform.name">
                            <Link
                                :href="useRoute('platforms.show', platform.slug)"
                                class="hover:underline">
                                {{ platform.name }}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="flex w-full items-center justify-end">
                <Button
                    type="button"
                    variant="primary"
                    @click="moreInfoModalOpen = false">
                    Okay
                </Button>
            </div>
        </template>
    </Modal>
</template>
