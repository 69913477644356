<script setup lang="ts">
import RatingContainer from '@/Components/Ratings/RatingContainer.vue'
import { type PropType } from 'vue'
import type { ArticleRating } from '@/Types/ArticleRating'

interface valueItem {
    value: string;
    min: string;
    max: string;
}

interface meta {
    values: valueItem[],
}

defineProps({
    rating: Object as PropType<ArticleRating<meta>>
})

function getPercentage (value: string): number {
    return (parseInt(value) / 100) * 100
}

// const percentage = computed(() => {
//     return (props.rating.meta.value / 100) * 100
// })
</script>

<template>
    <RatingContainer
        :rating
        :force-column="true">
        <div
            v-for="value in rating.meta.values"
            :key="value.value"
            class="mx-auto flex w-full flex-col items-center gap-2 min-h-12">
            <div class="relative h-3 w-full rounded-full bg-gradient-to-r from-red-500 to-green-500 lg:-mt-4">
                <div
                    v-for="n in 9"
                    :key="n"
                    class="absolute top-0 h-full -translate-x-1/2 bg-white w-[2px]"
                    :style="`left:${n*10}%;`" />

                <div
                    v-tooltip="`${getPercentage(value.value)}%`"
                    class="h-[calc(100%+1rem)] w-2.5 absolute top-1/2 -translate-y-1/2 -translate-x-1/2 border-2 border-white bg-primary rounded-lg"
                    :style="{ left: `${getPercentage(value.value)}%` }" />
            </div>
            <div
                v-if="value.min || value.max"
                class="flex w-full justify-between text-lg font-heading">
                <div class="break-words text-right max-w-80">
                    {{ value.min }}
                </div>
                <div class="break-words text-right max-w-80">
                    {{ value.max }}
                </div>
            </div>
        </div>
    </RatingContainer>
</template>
