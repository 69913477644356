<script setup lang="ts">
import Button from '@/Components/UI/Button.vue'
import PostSingle from '@/Components/Post/PostSingle.vue'
import type { PropType } from 'vue'
import { router } from '@inertiajs/vue3'
import type { Post } from '@/Types/Models/Post'
import { useRoute } from '@/Composables/useRoute.ts'
import { ChevronLeftIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
    post: Object as PropType<Post>,
    comments: Array as PropType<Comment[]>
})

function reload () {
    router.reload()
}

function deleted () {
    router.replace(useRoute('user.show.posts', props.post.user))
}
</script>

<template>
    <div class="mx-auto my-8 w-full max-w-4xl px-4 md:px-24">
        <div class="mb-4 flex">
            <Button
                variant="white"
                :href="useRoute('user.show.posts', post.user)">
                <ChevronLeftIcon class="mr-1 w-5" />
                Back to {{ post.user.name }}'s posts
            </Button>
        </div>
        <PostSingle
            :post="post"
            :show-pinned="false"
            :show-comments="true"
            :comments="comments"
            @deleted="deleted"
            @reload="reload" />
    </div>
</template>
