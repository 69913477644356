<script setup lang="ts">
import Card from '@/Components/UI/Card.vue'
import Button from '@/Components/UI/Button.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import { ref } from 'vue'
import { router } from '@inertiajs/vue3'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useUpdateSetting } from '@/Composables/useUpdateSetting'

const updateSetting = useUpdateSetting()
const showHelper = ref(!usePageProps().auth.settings.article_helper_closed)

function closeHelper (): void {
    showHelper.value = false
    updateSetting('article_helper_closed', true, () => {
        router.reload({
            // @ts-ignore
            preserveScroll: true,
            only: ['auth']
        })
    })
}
</script>

<template>
    <div>
        <div
            v-if="showHelper"
            class="mb-2 flex w-full justify-end">
            <Button
                variant="white"
                inner-class="flex items-center gap-2 text-xs"
                padding="py-1 px-2"
                @click="closeHelper">
                <XMarkIcon class="w-4" />
                Close
            </Button>
        </div>
        <Card v-if="showHelper">
            <SectionTitle font-size="text-xl">
                <div class="flex items-center">
                    Keys to a good article
                </div>
            </SectionTitle>
            <div class="prose">
                <ul>
                    <li>Engaging Title and Introduction</li>
                    <li>Eye-catching visuals and images</li>
                    <li>Concise and meaningful tags</li>
                    <li>Well-thought out conclusion</li>
                </ul>
            </div>
        </Card>
    </div>
</template>
